@import "/src/assets/css/style.scss";

.dashboard {

    &__target {
      padding-bottom: rem(45);

      @include media('>=md') {
        padding-bottom: rem(108);
      }
    }

    .target {

        &__header {
            width: 100%;
            padding-bottom: 0;

            @include media('>=lg') {
              padding-left: 0;
              margin-bottom: rem(36);
            }

            &::after {
                content: none;
            }

            &:last-of-type {
                margin-top: rem(33);

                @include media('>=lg') {
                  margin-top: rem(21);
                }
            }

            .heading_route {
                margin-bottom: 0;
            }
        }

        .goals {

            .card {
                margin: 0 auto rem(24);

                &.is-active {
                    box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.40);
                    backdrop-filter: blur(rem(30));
                }

                &-body {
                    padding: rem(22) rem(21) rem(15) rem(22);
                }
            }
        }
    }
}
