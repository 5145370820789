@import "/src/assets/css/style.scss";

.messages {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  padding-bottom: rem(40);

  &__content {
    width: 100%;
  }

  &__users {
    width: 100%;
    background-color: var(--bg-color-1);
    border-radius: rem(12);
    position: relative;

    &.show {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    ul {
      margin: 0;
      padding: 0 !important;
      list-style-type: none;

      li {
        white-space: nowrap;
        cursor: pointer;
        background-color: var(--bg-color-2);
        padding: rem(8) rem(12);
        @include font(16, 18);
        border-bottom: rem(1) var(--gray-1) solid;

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        font-weight: 800;
      }
    }

    .current__message_user {
      display: flex;
      padding: rem(10) rem(12);
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      &-name {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        @include font(14, 16, 0.28);
        font-weight: 600;

        svg {
          margin-right: rem(12);
        }
      }

      &-icon {
        svg {
          transform: rotate(90deg);
        }
        transition: 0.2s;

        &.show {
          transform: rotate(180deg);
        }
      }
    }

    .users__list {
      padding: rem(12) rem(4);
      background-color: var(--bg-color-2);
      opacity: 0;
      height: 0;
      width: 100%;
      overflow-y: scroll;
      transition: height 0.2s ease, opacity 0.2s ease;
      position: absolute;

      &-item {
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          margin-right: rem(12);
          height: rem(50);
          width: auto;
        }
      }

      &.show {
        opacity: 1;
        height: rem(300);
      }
    }
  }
}

.message__author {
  text-align: right;
  background-color: var(--primary-blue);
}

.message__caller {
  text-align: left;
  background-color: var(--bg-color-7);
}
