@import "/src/assets/css/style.scss";

.settings {

  &__form {

    @include media('>=lg') {
      display: flex;
      justify-content: space-between;
    }

    .form {
      max-width: rem(435);
      margin: 0 auto;

      @include media('>=lg') {
        width: 100%;
        max-width: 100%;
        padding-top: rem(1);
        margin: 0;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;

        @include media('>=md') {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        @include media('>=xxl') {
          padding: 0 rem(4);
        }
      }

      &-group {
        margin-bottom: rem(25);

        &:last-of-type {
          margin-bottom: rem(46);
        }

        @include media('>=md') {
          flex: 1 1 50%;
          max-width: calc(50% - rem(10));
        }

        @include media('>=lg') {
          flex: 1 1 100%;
          max-width: 100%;
        }

        @include media('>=xl') {
          flex: 1 1 50%;
          max-width: calc(50% - rem(12));
        }

        &-row {
          .form-field {
            padding: rem(6);

            .form-button {
              flex: 1;
              font-weight: 600;
              padding: rem(12) rem(9);
              border-radius: rem(8);
              transition: background-color 0.2s ease-in-out;

              &--active {
                background-color: var(--primary-red);
                box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);
                backdrop-filter: blur(rem(30));
              }
            }
          }
        }
      }

      &-field {
        padding: rem(12) rem(16);
      }

      &-control {
        @include font(16, 24);
      }

      .btn {
        padding: rem(12) rem(29) rem(11);
        margin: 0 auto;

        @include media('>=lg') {
          margin: 0;
        }
      }
    }
  }
}
