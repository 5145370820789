@import "/src/assets/css/style.scss";

.pagination__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media(">=lg") {
    flex-direction: row;
    justify-content: space-between;
  }

  .pagination__right {
    margin-top: rem(36);
    display: flex;
    align-items: center;

    span {
      margin-right: rem(16);
      white-space: nowrap;
    }

    &_item {
      width: rem(40);
      height: rem(40);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: rem(2) solid var(--gray-1);
      margin-right: rem(8);
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        background-color: var(--primary-red);
        border-color: var(--primary-red);
      }

      &.active {
        background-color: var(--primary-red);
        border-color: var(--primary-red);
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .pagination__left {
    margin-top: rem(36);
    display: flex;
    align-items: center;

    .pagination_arrow {
      background-color: var(--bg-color-2);
      border-radius: 50%;

      width: rem(40);
      height: rem(40);
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        transform: rotate(180deg);
      }

      &:disabled {
        opacity: 0.5;
        svg {
          path {
            stroke: var(--gray-1);
          }
        }
      }
    }

    .pagination__items_wr {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 rem(32);

      .pagination_item {
        margin-right: rem(8);
        width: rem(40);
        height: rem(40);
        display: flex;
        justify-content: center;
        align-items: center;
        border: rem(2) solid var(--gray-1);
        border-radius: 50%;
        transition: 0.3s;

        &:last-of-type {
          margin: 0;
        }

        &.active {
          background-color: var(--primary-red);
          border-color: var(--primary-red);
        }

        &:hover {
          cursor: pointer;
          background-color: var(--primary-red);
          border-color: var(--primary-red);
        }

        &:disabled {
          opacity: 0.4;
          cursor: inherit;
        }

        &:disabled:hover {
          background-color: transparent;
          border-color: var(--gray-1);
        }
      }
    }
  }
}
