@import "/src/assets/css/style.scss";

.package {
  @include font(16, 18);
  font-weight: 800;
}

.super-charged {
  background: linear-gradient(96deg, #f30a2e -3.21%, #4a88ff 107.31%);
  -webkit-background-clip: text;
  color: transparent;
  text-fill-color: transparent; // Dla przeglądarek, które nie obsługują powyższej właściwości
}

.xtreme {
  background: black;
  -webkit-background-clip: text;
  color: transparent;
  text-fill-color: transparent; // Dla przeglądarek, które nie obsługują powyższej właściwości
}
