@import "/src/assets/css/style.scss";

.why-worth {

  &__wrapper {
    padding: rem(64) 0 rem(43);

    @include media('>=lg') {
      padding: rem(19) 0 rem(92);
    }
  }

  &__header {
    margin-bottom: rem(35);

    @include media('>=md') {
      margin-bottom: rem(36);
    }

    & > h2 {
      @include font(16, 24, 3.2);
      font-family: 'BarlowCondensed';
      font-weight: 700;
      margin-bottom: rem(17);
      opacity: .5;

      @include media('>=md') {
        margin-bottom: rem(16);
      }
    }

    & > h3 {
      @include font(24, 22, .72);
      font-weight: 900;
      color: var(--color-white-1);

      @include media('>=md') {
        @include font(28, 32, .84);
      }
    }
  }

  &__content {
    @include media('>=md') {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__item {
    @include font(14, 20, .28);
    font-weight: 500;
    padding: rem(17) rem(16) rem(15);
    margin-bottom: rem(16);
    border-radius: rem(14);
    box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
    background-color: var(--bg-color-2);

    @include media('>=xs') {
      max-width: rem(319);
      margin: 0 auto rem(16);
    }

    @include media('>=md') {
      flex: 0 1 50%;
      max-width: calc(50% - rem(12.5));
      margin: 0;

      &:first-of-type,
      &:nth-child(2) {
        margin-bottom: rem(24);
      }
    }

    @include media('>=xl') {
      flex: 0 1 25%;
      max-width: calc(25% - rem(17));
      padding: rem(15) rem(16) rem(15) rem(14);
      margin: 0 !important;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    & > .svg-container {
      width: 100%;
      max-width: rem(56);
      min-height: rem(56);
      margin-right: rem(16);
      padding-top: rem(1);

      @include media('>=xl') {
        padding-top: 0;

        & + p {
          padding-top: rem(2);
        }
      }

      & > svg {
        width: 100%;
        fill: var(--primary-red);
      }
    }

    &:nth-child(2n) {

      & > .svg-container {

        & > svg {
          fill: var(--primary-blue);
        }
      }
    }

    & > p > strong {
      font-weight: 800;
    }
  }
}
