@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Thin.ttf) format("truetype");
	font-weight: 100;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-ThinItalic.ttf) format("truetype");
	font-weight: 100;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-ExtraLight.ttf) format("truetype");
	font-weight: 200;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-ExtraLightItalic.ttf) format("truetype");
	font-weight: 200;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Light.ttf) format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-LightItalic.ttf) format("truetype");
	font-weight: 300;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Regular.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Italic.ttf) format("truetype");
	font-weight: 400;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Medium.ttf) format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-MediumItalic.ttf) format("truetype");
	font-weight: 500;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-SemiBold.ttf) format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-SemiBoldItalic.ttf) format("truetype");
	font-weight: 600;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Bold.ttf) format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-BoldItalic.ttf) format("truetype");
	font-weight: 700;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-ExtraBold.ttf) format("truetype");
	font-weight: 800;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-ExtraBoldItalic.ttf) format("truetype");
	font-weight: 800;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-Black.ttf) format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Barlow";
	src: url(../../fonts/Barlow-BlackItalic.ttf) format("truetype");
	font-weight: 900;
	font-style: italic;
	font-display: block;
}

@font-face {
	font-family: "BarlowCondensed";
	src: url(../../fonts/BarlowCondensed-Bold.ttf) format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "BarlowCondensed";
	src: url(../../fonts/BarlowCondensed-BoldItalic.ttf) format("truetype");
	font-weight: 700;
	font-style: italic;
	font-display: block;
}
