@import "/src/assets/css/style.scss";

.login_wr {
  padding: rem(100) 0;

  .login-heading {
    margin-bottom: rem(42);

    @include media('>=md') {
      margin-bottom: rem(33);
    }
  }

  .form-inner {
    max-width: rem(435);
    margin: 0 auto;
    margin: 0 auto;

    .link__resetpass {
      display: block;
      color: var(--color-white);
      text-decoration: none;
      margin-top: rem(-20);
      width: fit-content;
      margin-right: auto;
      margin-left: auto;

      &:hover {
        color: var(--primary-red);
      }
    }
  }

  .error-text {
    color: var(--primary-red);
  }

  .button_wr {
    display: flex;
    flex-direction: column;
    width: rem(220);
    margin: 0 auto;

    a,
    button {
      margin-top: rem(24);
    }
  }

  .alert_wr {
    max-width: rem(435);
    margin: rem(16) auto;
    opacity: 0;
    height: rem(50);

    &.show {
      opacity: 1;
    }
  }
}
