@import "/src/assets/css/style.scss";

.google-opinions {

    &__wrapper {
        padding: rem(87) 0 rem(85);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - rem(36));
            transform: translateX(-50%);
            min-height: rem(2);
            opacity: .16;
            background: linear-gradient(to right, rgba(255,255,255,0) 0%, #FFFFFF 50%, rgba(255,255,255,0) 100%);
        }

        @include media('>=xl') {
            max-width: rem(1133);
            display: flex;
            justify-content: space-between;
            padding: rem(93) 0 rem(54);
            margin: 0 auto;
        }
    }

    &__col {
        @include font(16, 24, 0.16);

        &--left {
            margin-bottom: rem(24);

            @include media('>=sm') {
                max-width: rem(566);
                margin: 0 auto rem(24);
            }

            @include media('>=xl') {
                padding: rem(42) rem(10) rem(42) rem(22);
                margin: 0;
            }

            .column {

                &__header {
                    text-align: center;
                    margin-bottom: rem(28);

                    @include media('>=md') {
                        margin-bottom: rem(25);
                    }

                    @include media('>=xl') {
                        text-align: left;
                    }

                    & > span {
                        @include font(16, 24, 3.2);
                        font-family: BarlowCondensed;
                        font-weight: 700;
                        color: var(--primary-red);
                        margin-bottom: rem(4);

                        @include media('>=md') {
                            margin-bottom: rem(8);
                        }
                    }

                    & > h2 {
                        @include font(32, 42, .96);
                        font-weight: 900;
                        margin-bottom: 0;

                        @include media('>=md') {
                            @include font(42, 56, 1.26);
                        }

                        @include media('>=xl') {
                            text-align: left;
                        }
                    }
                }

                &__content {
                    @include font(16, 24, .16);
                    text-align: center;
                    margin-bottom: rem(37);

                    @include media('>=md') {
                        margin-bottom: rem(36);
                    }

                    @include media('>=xl') {
                        text-align: left;
                    }
                }

                &__footer {
                    @include font(18, 21.6, .54);
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;

                    @include media('>=xl') {
                        justify-content: flex-start;
                    }

                    & > * {
                        margin-right: rem(8);
                        margin-bottom: rem(7);

                        @include media('>=xl') {
                            margin-bottom: 0;
                        }
                    }

                    span {
                        opacity: .85;

                        &:last-of-type {
                            width: 100%;
                            text-align: center;
                            margin-right: 0;
                            margin-bottom: 0;

                            @include media('>=xl') {
                                width: auto;
                                text-align: left;
                                margin-left: rem(7);
                            }
                        }

                        strong {
                            font-weight: 800;

                            @include media('>=xl') {
                                margin-left: rem(4);
                            }
                        }
                    }
                }
            }
        }

        &--right {

            @include media('>=sm') {
                max-width: rem(546);
                margin: 0 auto;
            }

            @include media('>=xl') {
                margin: 0;
            }
        }
    }

    .splide {
        box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);

        &__list {
            cursor: grab;

            &:active {
            cursor: grabbing;
            }
        }

        .slide {

            &__wrapper {
                padding: rem(21) rem(24) rem(24) rem(22);

                @include media('>=sm') {
                    padding: rem(22) rem(36) rem(24) rem(34);
                }
            }

            &__header {
                margin-bottom: rem(17);

                @include media('>=sm') {
                    margin-bottom: rem(16);
                }
            }

            &__content {
                @include font(16, 24, .16);
                padding: 0 rem(2);
                margin-bottom: rem(16);
            }

            &__footer {
                @include font(16, 24, .16);
                font-weight: 800;
                padding: 0 rem(2);
                opacity: .75;
            }
        }

        &__slide {
            border-radius: rem(14);
            // box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
            background-color: var(--bg-color-2);

            .stars {

                .svg-container {
                    max-width: rem(20);
                    margin-right: rem(1);

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .slider {

        &__navigation {
            min-width: rem(204);
            min-height: rem(42);
            margin: rem(35) auto 0;

            @include media('>=xl') {
                margin: rem(23) auto 0;
            }
        }

        &__arrow {
            top: 0;
            transition: opacity .1s ease-in-out;

            &--prev {
                left: 0;
            }

            &--next {
                right: 0;
                transform: rotate(180deg);
            }

            &--disabled {
                opacity: .25;
            }
        }

        &__pagination {
            width: 100%;
            text-align: center;

            .item {
                margin-right: rem(8);

                &:last-of-type {
                    margin-right: 0;
                }

                & > span {
                    min-width: rem(12);
                    min-height: rem(12);
                    border: rem(2) solid var(--color-white);
                    transition: background-color .1s ease-in-out;
                }

                &.active {

                    & > span {
                        background-color: var(--color-white);
                    }
                }
            }
        }
    }
}
