@import "/src/assets/css/style.scss";

.trainer__dashboard {
  padding-bottom: rem(60);
  &-heading,
  &-second-heading {
    @include font(28, 32, 0.84);
    font-weight: 900;
    font-style: italic;
    margin-bottom: rem(42);
    width: 100%;

    @include media(">=md") {
      @include font(42, 56, 0.26);
      margin-bottom: rem(33);
    }

    @include media(">=lg") {
    }

    & > span {
      display: inline-block;
      color: var(--color-white);
      -webkit-text-fill-color: var(--bg-color);
      -webkit-text-stroke-width: rem(1.5);
      -webkit-text-stroke-color: var(--color-white);
    }
  }

  &-second-heading {
    @include font(24, 28, 0.84);
    @include media(">=lg") {
      @include font(28, 32, 0.84);

      margin-top: rem(80);
    }
  }
}
