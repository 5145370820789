@import "/src/assets/css/style.scss";

.dashboard {

  &__settings {
    padding-bottom: rem(45);

    @include media('>=md') {
      padding-bottom: rem(132);
    }

    .settings {

      .account {

        &__avatar {
          min-width: rem(184);
          max-width: rem(184);
          height: rem(184);
          margin: 0 auto rem(25);

          @include media('>=lg') {
            min-width: rem(200);
            max-width: rem(200);
            height: rem(200);
          }

          @include media('>=xl') {
            min-width: rem(246);
            max-width: rem(246);
            height: rem(246);
          }
        }

        &__info {
          padding: 0 rem(60);
          margin-bottom: rem(37);

          @include media('>=lg') {
            max-width: rem(200);
            padding: 0;
            margin-right: rem(40);
          }

          @include media('>=xl') {
            max-width: rem(246);
          }

          @include media('>=xxl') {
            margin-right: rem(92);
          }

          &__details {
            @include font(14, 20);
            margin-bottom: rem(21);
            opacity: .75;

            & > span {

              &:first-of-type {
                margin-bottom: rem(2);
              }
            }
          }

          .btn {
            @include font(13, 15.6, 0.78);
            padding: rem(10) rem(15) rem(9);
            border-color: rgba(255, 255, 255, 0.35);
            margin: 0 auto;

            @include media('>=lg') {
              margin: 0 auto;
            }

            & > .svg-container {
              margin-left: rem(9);
            }

            & + span {
              @include font(12, 16, 0.12);
              margin-top: rem(8);

              @include media('>=lg') {
                padding: 0 rem(20);
              }
            }
          }
        }
      }
    }
  }
}
