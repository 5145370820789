@import "/src/assets/css/style.scss";

.wrapper {
  background-color: var(--bg-color-1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: rem(16);

  p {
    font-size: rem(32) !important;
  }
}
