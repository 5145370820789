@import "/src/assets/css/style.scss";

.footer {
  background-color: var(--bg-color-1);

  &-top {
    padding-top: rem(64);

    @include media(">=lg") {
      display: flex;
      padding: rem(51) 0 rem(44);
    }
  }

  &__col {
    &--left {
      text-align: center;
      margin-bottom: rem(43);

      @include media(">=lg") {
        width: 100%;
        max-width: rem(350);
        text-align: left;
        padding-top: rem(5);
        margin: 0 rem(50.5) 0 0;
      }

      @include media(">=xxl") {
        margin: 0 rem(101) 0 0;
      }
    }

    &--center {
      display: flex;
      justify-content: center;
      padding: 0 rem(4);
      margin-bottom: rem(43);

      @include media(">=lg") {
        display: block;
        width: 100%;
        max-width: rem(302);
        padding: 0;
        margin-bottom: 0;
      }

      .trainer {
        flex: 1 1 50%;
        max-width: calc(50% - rem(10));
        text-align: center;

        @include media(">=lg") {
          max-width: 100%;
          text-align: left;
          margin-bottom: rem(40);

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        @include media(">=xl") {
          display: flex;
        }

        &__avatar {
          margin-bottom: rem(12);
          width: 96px;
          min-width: 96px;
          height: 96px;
          border-radius: 50px;
          box-shadow: 0px 0px 0px 4px #2a89fe;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            image-rendering: smooth;
            image-rendering: -webkit-optimize-contrast;
          }

          @include media(">=xl") {
            margin: 0 rem(28) 0 0;
          }
        }

        & > .trainer__details {
          padding-top: rem(10);

          & > h3 {
            @include font(18, 20, 0.54);
            font-weight: 900;
            margin-bottom: rem(6);

            @include media(">=xl") {
              margin-bottom: rem(10);
            }

            @include media(">=xxl") {
              br {
                display: none;
              }
            }
          }
        }

        &__contact {
          margin-bottom: rem(6);
          opacity: 0.75;

          @include media(">=xl") {
            margin-bottom: rem(8);
          }

          & > a {
            @include font(14, 20);
            font-weight: 500;
            color: var(--color-white);

            @include media(">=md") {
              @include font(16, 24);
            }
          }
        }

        &__instagram {
          opacity: 0.75;

          & > a {
            font-weight: 500;
            color: var(--color-white);

            & > span {
              margin-left: rem(8);
            }
          }
        }
      }
    }

    &--right {
      position: relative;
      padding: rem(49) 0 rem(47);

      @include media(">=lg") {
        width: 100%;
        max-width: rem(475);
        padding: rem(5) 0 0 0;
        margin-left: auto;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        min-height: rem(1);
        opacity: 0.2;
        background-color: var(--color-white);

        @include media(">=lg") {
          content: none;
        }
      }

      &::before {
        top: 0;
        left: 0;
      }

      &::after {
        bottom: 0;
        left: 0;
      }

      .footer__nav {
        display: flex;
        justify-content: space-between;
        margin-bottom: rem(11);

        @include media(">=lg") {
          margin-bottom: 0;
        }

        & > ul {
          flex: 1 1 50%;
          max-width: calc(50% - rem(24));
          text-align: center;

          @include media(">=lg") {
            display: flex;
            flex-direction: column;
            text-align: left;
            margin-bottom: 0;
          }

          @include media(">=xl") {
            max-width: calc(50% - rem(38));
          }

          & > li {
            @include font(15, 18, 1.2);
            margin-bottom: rem(20);
            opacity: 0.8;

            & > a {
              font-weight: 600;
              color: var(--color-white);
            }
          }

          &.menu-policies {
            & > li {
              &:last-of-type {
                opacity: 1;

                & > a {
                  font-weight: 800;
                }
              }
            }
          }
        }
      }
    }
  }

  &__logo {
    max-width: rem(375);
    margin: 0 auto rem(35);

    @include media(">=md") {
      display: flex;
      align-items: center;
    }

    @include media(">=lg") {
      margin: 0 0 rem(47);
    }

    &__wrapper {
      padding-bottom: rem(16);
      margin-bottom: rem(16);
      max-width: rem(375);

      @include media(">=md") {
        width: 100%;
        padding: 0 rem(25) 0 0;
        margin: 0 rem(23) 0 0;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        min-width: rem(64);
        min-height: rem(2);
        background-color: var(--primary-red);

        @include media(">=md") {
          right: 0;
          bottom: 50%;
          left: auto;
          min-width: rem(2);
          min-height: rem(50);
          transform: translateY(50%);
        }
      }
    }

    & > h2 {
      @include font(22, 26, 0.66);
      font-weight: 900;

      @include media(">=md") {
        text-align: left;
        margin-bottom: 0;
      }
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;

    & > a {
      width: max-content;
      font-weight: 500;
      color: var(--color-white);
      margin: 0 auto rem(15);

      @include media(">=lg") {
        margin: 0 0 rem(15);

        & > svg {
          max-width: rem(24);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      & > span {
        margin-left: rem(11);

        @include media(">=lg") {
          margin-left: rem(12);
        }
      }
    }
  }

  &__return {
    max-width: rem(91);
    margin: 0 auto;
    cursor: pointer;

    @include media(">=lg") {
      max-width: 100%;
      margin: auto 0 rem(23);
    }

    & > span {
      text-align: center;
      color: var(--color-white);

      & > .svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: rem(64);
        height: rem(64);
        border-radius: 50%;
        box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);
        backdrop-filter: blur(rem(30));
        background-color: var(--primary-red);
        margin: 0 auto rem(14);

        @include media(">=lg") {
          width: 100%;
          max-width: rem(40);
          height: rem(40);
          margin: 0 rem(13) 0 0;

          & > svg {
            max-width: rem(20);
          }
        }
      }

      & > span {
        @include font(14, 20);
        font-weight: 500;
      }
    }
  }

  &-bottom {
    padding: rem(33) 0 rem(63);
    @include font(14, 20);
    font-weight: 500;
    text-align: center;
    opacity: 0.5;

    @include media(">=lg") {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(17) 0 rem(35);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: rem(1);
        background-color: var(--color-white);
        opacity: 0.2;
      }
    }

    & > span {
      margin-bottom: rem(8);

      @include media(">=lg") {
        margin-bottom: 0;
      }

      &:nth-child(2) {
        @include font(14, 20, 2.24);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
