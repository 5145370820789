@import "/src/assets/css/style.scss";

.heading {
  font-style: italic;
  text-align: center;

  &[data_set="h1"] {
    @include font(48, 56, 0.03);
    font-weight: 900;
  }

  &[data_set="h2"] {
    @include font(22, 24, 0.03);
    font-weight: 900;
  }

  &[data_set="h3"] {
    @include font(32, 42, 0.96);
    font-weight: 900;
  }

  & > span {
    display: inline-block;
    color: var(--color-white);
    -webkit-text-fill-color: var(--bg-color);
    -webkit-text-stroke-width: rem(1.5);
    -webkit-text-stroke-color: var(--color-white);
  }

  @include media(">=lg") {
    &[data_set="h1"] {
      @include font(72, 76, 0.03);
    }
    &[data_set="h2"] {
      @include font(28, 32, 0.03);
    }
    &[data_set="h3"] {
      @include font(42, 42, 0.03);
      font-weight: 900;
    }
  }
}
