@import "/src/assets/css/style.scss";

.blog {
    background-color: var(--bg-color-1);

    &__wrapper {
        padding: rem(86) 0;

        @include media('>=md') {
            padding: rem(93) 0;
        }
    }

    &__header {
        text-align: center;
        margin-bottom: rem(49);

        @include media('>=xl') {
            margin-bottom: rem(24)
        }

        .header {

            &__content {
                & > span {
                    @include font(16, 24, 3.2);
                    font-family: BarlowCondensed;
                    font-weight: 700;
                    color: var(--primary-red);
                    margin-bottom: rem(5);

                    @include media('>=md') {
                        margin-bottom: rem(8);
                    }
                }

                & > h2[data_set=h2] {
                    @include font(32, 42, .96);
                    font-weight: 900;
                    margin-bottom: 0;

                    @include media('>=md') {
                        @include font(42, 56, 1.26);
                    }
                }
            }
        }
    }

    &__footer {
        margin-top: rem(64);

        & > a {

            & > button {
                @include font(15, 18, .6);
                padding: rem(12) rem(32);
            }
        }
    }
}
