@import "/src/assets/css/style.scss";

.development-center {
  background-color: var(--bg-color-1);

  &__wrapper {
    padding: rem(41) 0 rem(86);

    @include media(">=xl") {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: rem(41) 0 rem(23);
    }

    @include media(">=xxl") {
      padding: rem(41) rem(36) rem(23) rem(64);
    }
  }

  &__image {
    justify-content: center;
    margin: 0 rem(-16) rem(-2.5);

    @include media(">=xl") {
      flex: 1 1 50%;
      max-width: calc(50% - rem(69));
      margin: 0;
    }

    & > div {
      padding-top: rem(35);

      @include media(">=xl") {
        padding-top: rem(37);
      }

      .animated-arrows {
        position: absolute;

        @include media(">=xl") {
          &--mobile {
            display: none !important;
          }
        }

        &:nth-child(1) {
          top: 0;
          left: calc(30% + rem(1));

          @include media(">=xl") {
            top: rem(14);
            left: calc(10% + rem(3));
          }
        }

        &:nth-child(2) {
          top: rem(6);
          left: calc(15% + rem(2));
        }

        &:nth-child(3) {
          bottom: calc(9% + rem(2));
          left: 16%;
        }

        &:nth-child(4) {
          bottom: calc(7% + rem(3));
          left: calc(26% + rem(1));
        }

        &:nth-child(5) {
          display: none !important;

          @include media(">=xl") {
            display: block;
            top: rem(3);
            left: calc(31% + rem(4));
          }
        }

        &:nth-child(6) {
          display: none !important;

          @include media(">=xl") {
            display: block !important;
            top: rem(3);
            left: calc(24% + rem(1));
          }
        }

        &:nth-child(7) {
          display: none !important;

          @include media(">=xl") {
            display: block !important;
            bottom: calc(11%);
            left: 26%;
          }
        }

        &:nth-child(8) {
          display: none !important;

          @include media(">=xl") {
            display: block !important;
            bottom: calc(24% + rem(2));
            right: rem(-2);
          }
        }
      }
    }
  }

  &__content {
    @include font(16, 24, 0.16);
    text-align: center;
    align-items: center;

    @include media(">=xl") {
      flex: 1 1 50%;
      align-items: flex-start;
      max-width: calc(50% - rem(10));
      text-align: left;
    }

    @include media(">=xxl") {
      padding-right: rem(50);
    }

    & > .heading[data_set="h2"] {
      @include font(16, 24, 3.2);
      font-family: "BarlowCondensed";
      font-weight: 700;
      color: var(--primary-red);
      margin-bottom: rem(4);
    }

    & > .heading[data_set="h3"] {
      margin-bottom: rem(28);

      @include media(">=md") {
        @include font(42, 56, 1.26);
      }
    }

    & > p {
      margin-bottom: rem(36);
    }

    & > ul {
      max-width: rem(328);
      text-align: left;
      margin-bottom: rem(28);

      @include media(">=md") {
        max-width: 100%;
      }

      @include media(">=xl") {
        width: 100%;
      }

      & > li {
        @include font(16, 24);
        letter-spacing: 0;
        font-weight: 500;
        margin-bottom: rem(16);
        opacity: 0.8;

        @include media(">=md") {
          @include font(18, 26);
          letter-spacing: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        & > .svg-container {
          position: relative;
          top: rem(-1);
          width: 100%;
          max-width: rem(24);
          height: rem(24);
          border-radius: 50%;
          background-color: var(--gray-1);
          margin-right: rem(14);
        }
      }
    }

    & > span {
      @include font(12, 16, 0.12);
      opacity: 0.8;
    }
  }

  &__button {
    margin-top: rem(28);

    & > a > .btn-primary {
      @include font(15, 18, 0.6);
      padding: rem(12) rem(36) rem(13);
    }
  }
}
