@import "/src/assets/css/style.scss";

.splide__track {
  &:after,
  &:before {
    display: none !important;
  }
}

.section__wrapper_packages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(86) 0 rem(81);
  overflow: hidden;

  @include media(">=lg") {
    padding: rem(80) 0 rem(81);
  }

  .buttons__wrapper {
    margin-top: rem(100);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: rem(14);

    span {
      font-style: italic;
      color: var(--gray-50);
      font-weight: 900;
      @include font(16, 18, 1);
    }

    @include media(">=lg") {
      flex-direction: row;
      margin-top: rem(41);
    }
  }

  .splide__pagination {
    position: absolute;
    bottom: rem(-42);
    left: 50%;
    transform: translateX(-50%);

    .splide__pagination__page {
      background-color: transparent;
      border: 2px solid white;
      opacity: 1;
      width: rem(12);
      height: rem(12);
      margin-right: rem(3);

      &.is-active {
        width: rem(12);
        height: rem(12);
        background-color: white;
        transform: none;
      }
    }

    @include media(">=lg") {
      display: none;
    }
  }

  .splide__arrows {
    position: absolute;
    width: rem(218);
    bottom: rem(-34);
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);

    @include media(">=lg") {
      display: none;
    }

    .custom_arrow {
      background: var(--gray-1);
      width: rem(40);
      height: rem(40);

      svg {
        fill: white;
        height: rem(16);
        width: auto;
      }
    }
  }

  .text__wrapeper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .section__paragraph {
      color: var(--primary-blue);
      @include font(16, 24, 0.32);
      font-weight: 700;
      font-style: italic;
      margin-bottom: rem(4);

      @include media(">=lg") {
        margin-bottom: rem(14);
      }
    }

    .section__paragraph_second {
      margin-top: rem(18);
      text-align: center;
      padding: 0 rem(20);

      @include media(">=lg") {
        max-width: rem(880);
        margin-top: rem(22);
      }
    }
  }

  .packages__wrapper {
    justify-content: center;
    overflow: visible;
    display: flex;
    position: relative;
    margin-top: rem(32);

    .splide__track {
      overflow: visible;
    }

    @media screen and (max-width: 1350px) {
      .splide__list {
        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }
    }

    .slider__plans {
      overflow: visible;
      height: rem(580);
      width: 100%;
      background-color: var(--bg-color);

      .slider__element {
        height: 100%;
        max-height: rem(620);
        width: 100%;
        max-width: rem(324);
        padding: 0 rem(12) rem(60) rem(12);

        .card {

          &::before,
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border-radius: rem(14);
            opacity: 0;
          }

          &::before {
            border: rem(2) solid;
            transition: opacity 100ms ease-out;
          }

          &::after {
            border-radius: rem(14);
            backdrop-filter: blur(rem(30));
            z-index: -1;
            transition: opacity 100ms ease-out;
          }

          &:hover {

            &::before,
            &::after {
              opacity: 1;
            }
          }
        }

        &:nth-child(1) {

          .card {

            &::before {
              border-color: var(--primary-red);
            }

            &::after {
              box-shadow: 0px 0.25rem 4rem 0px rgba(251, 72, 72, 0.4) !important;
            }
          }
        }

        &:nth-child(2) {

          .card {
            color: var(--bg-color-1);
            background: linear-gradient(342deg, #fff 0.95%, #e3edf9 96.94%);

            &::before {
              border-color: rgb(255, 165, 0);
            }

            &::after {
              box-shadow: 0px rem(4) rem(64) 0px rgba(255, 105, 0, 0.35) !important;
            }
          }
        }

        &:nth-child(3) {

          .card {
            background: linear-gradient(156deg, #f43b57 9.78%, #1959a8 42.36%);

            &::before {
              border-color: rgb(244, 59, 87);
            }

            &::after {
              box-shadow: 0px rem(4) rem(64) 0px rgba(25, 89, 168, 0.35) !important;
            }
          }
        }

        &:nth-child(4) {

          .card {
            background: var(--bg-color-3);

            &::before {
              border-color: rgb(160, 160, 160);
            }

            &::after {
              box-shadow: 0px rem(4) rem(64) 0px rgba(255, 255, 255, 0.35) !important;
            }
          }
        }

        &:nth-child(5) {

          .card {
            background: linear-gradient(162.44deg, #1959A8 6.42%, #005FD3 53.4%, #0A2342 99.44%);

            &::before {
              border-color: rgba(229, 138, 0, 1);
            }

            &::after {
              box-shadow: 0px rem(4) rem(64) 0px rgba(25, 89, 168, 0.35) !important;
            }
          }
        }

        &:nth-child(6) {

          .card {
            background: linear-gradient(166.41deg, #BB001D 10.14%, #DC2E49 39.53%, #BB001D 65.39%, #8E2233 106.65%);

            &::before {
              border-color: rgb(160, 160, 160);
            }

            &::after {
              box-shadow: 0px rem(4) rem(64) 0px rgba(255, 255, 255, 0.35) !important;
            }
          }
        }

        .card {
          width: 100%;
          height: 100%;
          border-radius: rem(14);
          background-color: var(--bg-color-2);
          box-shadow: rem(3) rem(23) rem(48) 0 rgba(0, 0, 0, 0.35) !important;
          margin-bottom: rem(24);
          display: flex;
          flex-direction: column;

          @include media(">=md") {
            // flex: 1 1 50%;
            // max-width: calc(50% - rem(12));
            margin-bottom: 0;
          }

          @include media(">=lg") {
            // flex: 1 1 33%;
            // max-width: calc(33% - rem(12));
          }

          @include media(">=xl") {
            // flex: 1;
            // max-width: rem(318);
            margin-right: 24px;
          }

          &:nth-child(1),
          &:nth-child(2) {
            @include media(">=md") {
              margin-bottom: rem(24);
            }

            @include media(">=lg") {
              margin-bottom: 0;
            }
          }

          &:nth-child(2) {
            color: var(--bg-color-1);
            background: linear-gradient(342deg, #fff 0.95%, #e3edf9 96.94%);
          }

          &:nth-child(3) {
            background: linear-gradient(156deg, #f43b57 9.78%, #1959a8 42.36%);

            @include media(">=lg") {
              margin-bottom: 0;
            }
          }

          &:nth-child(4) {
            background: var(--bg-color-3);
          }

          &:last-of-type {
            margin-bottom: 0;

            @include media(">=lg") {
              margin-top: rem(24);
            }

            @include media(">=xl") {
              margin-top: 0;
              margin-right: 0;
            }
          }

          &-tag {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: rem(134);
            text-align: center;
            padding: rem(2) 0 rem(1);
            border-radius: 0 0 rem(8) rem(8);
            z-index: 2;

            &--blue {
              background-color: var(--primary-blue);
            }

            &--orange {
              background-color: rgba(229, 138, 0, 1);
            }

            &--red {
              background-color: var(--third-red);
            }

            & > span {
              @include font(14, 24, 0.82);
              font-weight: 700;
            }
          }

          &-body {
            position: relative;
            padding-top: rem(55);
            padding-bottom: rem(36);
            display: flex;
            flex-direction: column;
            z-index: 2;

            @include media(">=md") {
              padding: rem(54) rem(16) rem(16);
              padding-top: rem(54);
              padding-bottom: rem(16);
            }
          }

          &-header {
            margin-bottom: rem(22);

            @include media(">=md") {
              margin-bottom: rem(23);
            }
          }

          &-content {
            @include media(">=md") {
              margin-bottom: rem(34);
            }
          }

          &-title {
            @include font(24, 22, 0.72);
            font-weight: 900;
            margin-bottom: rem(7);

            @include media(">=md") {
              @include font(28, 32, 0.84);
              margin-bottom: rem(12);
            }
          }

          &-time {
            @include font(18, 22, 0.54);
            font-weight: 700;
            opacity: 0.7;
          }

          &-description {
            @include font(14, 18.2);
            font-weight: 600;
            padding: rem(8) 0;
            border-top: rem(1) solid #fff;
            border-bottom: rem(1) solid #fff;
            margin-bottom: rem(13);

            @include media(">=md") {
              margin-bottom: rem(12);
            }
          }

          &-text,
          &-list__item {
            @include font(14, 20);
          }

          &-text {
            margin-bottom: rem(13);

            @include media(">=md") {
              margin-bottom: rem(12);
            }

            & > strong {
              font-weight: 800;
            }
          }

          &-list {
            & > li {
              margin-bottom: rem(12);
              padding-left: rem(28);

              & > .svg-container {
                top: 50%;
                left: rem(-4);
                transform: translateY(calc(-50% - rem(1)));
              }

              & > span {
                font-weight: 500;
              }
            }
          }

          &-footer {
            // margin-top: auto !important;
            display: flex;

            flex-grow: 1 !important;
            align-items: flex-end;
            justify-content: center;

            @include media(">=md") {
            }

            .price {
              @include font(22, 22, 0.66);
              font-weight: 700;
              margin-right: rem(15);

              &--original {
                @include font(16, 22, 0.48);
                font-weight: 700;
                text-decoration: line-through;
                opacity: 0.48;
              }
            }
          }
        }

        @include media(">=lg") {
          max-width: 100%;
        }
      }
    }

    //     ul {
    //       padding: 0;
    //       li {
    //         list-style-type: none;
    //         //   margin: 0;
    //       }
    //     }

    @include media(">=md") {
      justify-content: space-between;
      padding: 0 rem(40);
      margin-top: rem(52);
    }
  }
}
