@import "/src/assets/css/style.scss";

.faq-form {
  &__wrapper {
    @include font(14, 20);
    font-weight: 500;
    padding: rem(64) 0;

    @include media(">=md") {
      padding-bottom: rem(120);
    }

    @include media(">=lg") {
      display: flex;
      justify-content: space-between;
    }
  }

  &__left,
  &__right {
    @include media(">=lg") {
      flex: 1 1 50%;
    }
  }

  &__left {
    margin-bottom: rem(24);

    @include media(">=lg") {
      max-width: calc(50% - rem(14));
      margin-bottom: 0;
    }

    & > .faq {
      & > .container {
        max-width: 100%;
        padding: 0;

        & > .faq__wrapper {
          padding: 0;
        }
      }
    }
  }

  &__right {
    max-width: rem(658);
    box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
    border-radius: rem(14);
    background-color: var(--bg-color-2);
    margin: 0 auto;
    overflow: hidden;

    @include media(">=lg") {
      max-width: calc(50% - rem(14));
    }

    @include media(">=xl") {
      max-width: calc(50% - rem(50));
    }

    & > p {
      margin-bottom: 0;
      opacity: 0.8;
    }

    .right {
      &__wrapper {
        padding: rem(37) rem(36);
      }

      &__header {
        margin-bottom: rem(12);

        @include media(">=xl") {
          display: flex;
          justify-content: space-between;
        }
      }

      &__heading {
        margin-bottom: rem(25);

        .heading {
          @include media(">=xl") {
            text-align: left;
          }
        }

        .heading[data_set="h2"] {
          @include font(16, 24, 3.2);
          font-family: "BarlowCondensed";
          font-weight: 700;
          color: var(--primary-red);
          margin-bottom: rem(17);
        }

        .heading[data_set="h3"] {
          @include font(34, 22, 1.02);
          font-weight: 900;
          margin-bottom: 0;
        }
      }

      &__avatars {
        justify-content: center;

        & > .avatar {
          max-width: rem(72);
          margin: 0 rem(4);
        }
      }
    }
  }
}

.form__wrapper {
  &_group {
    display: flex;
    flex-direction: column;
    gap: rem(20);

    .form-group {
      width: 100%;
    }

    @include media(">=lg") {
      flex-direction: row;
    }
  }

  .btn_wr {
    margin-top: rem(36);

    button {
      width: 100%;
    }

    @include media(">=lg") {
      width: rem(310);
    }
  }
}
