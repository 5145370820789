@import "/src/assets/css/style.scss";

.dashboard {
  display: flex;
  flex-direction: column;

  @include media(">=lg") {
    flex-direction: row;
  }

  &__header {
    padding-bottom: rem(24);
    margin-bottom: rem(36);

    @include font(16, 24, 0.16);
    text-align: center;

    @include media(">=lg") {
      text-align: left;
      padding-left: rem(2);
      margin-bottom: rem(48);
    }

    & > p {
      max-width: rem(659);
      margin: 0 auto;

      @include media(">=lg") {
        margin-left: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: rem(1);
      background-color: var(--color-white);
      opacity: 0.25;
    }

    & > p {
      color: currentColor;
      opacity: 0.75;

      & > a {
        display: inline-block;
        color: currentColor;
        text-decoration: underline;
      }
    }
  }

  .heading_route {
    margin-bottom: rem(24);

    @include media(">=md") {
      margin-bottom: rem(25);
    }
  }

  &__content {
    margin-top: rem(47);
    padding: 0 rem(16);
    flex-grow: 1;

    @include media(">=md") {
      padding: 0 rem(48);
    }

    @include media(">=lg") {
      max-width: rem(1052);
      padding-left: 0;
      margin-top: rem(86);
    }
  }

  .account {
    padding-bottom: rem(48);

    @include media(">=lg") {
      padding-bottom: rem(116);
    }

    &__header {
      margin-bottom: rem(47);

      @include media(">=md") {
        margin-bottom: rem(55);
      }

      @include media(">=xl") {
        display: flex;
      }
    }

    &__info {
      margin-bottom: rem(47);

      @include media(">=xl") {
        width: 100%;
        max-width: rem(316);
        margin-right: rem(27);
        margin-bottom: 0;
      }
    }

    &__avatar {
      width: 100%;
      min-height: rem(100);
      max-width: rem(100);
      min-height: rem(100);
      border-radius: 50%;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--secondary-blue);
        z-index: -1;
      }

      & > img {
        width: 100%;
        max-width: calc(100% - rem(8));
        border-radius: 50%;
      }
    }

    &__data {
      padding-left: rem(16);

      @include media(">=md") {
        padding-left: rem(18);
      }

      & > span {
        @include font(14, 20);
        opacity: 0.75;

        &.is-strong {
          @include font(16, 24, 0.16);
          opacity: 1;

          strong {
            font-weight: 800;
          }
        }
      }
    }

    &__name {
      margin-bottom: rem(3);
    }

    &__email {
      margin-bottom: rem(2);
    }

    &__created_at {
      margin-bottom: rem(12);
    }

    &__settings {
      @include font(14, 20);
      font-weight: 500;
    }

    &__settings {
      text-decoration: none;
      color: var(--color-white);

      & > span {
        margin-right: rem(10);
      }
    }

    &__tips {
      border: rem(1) solid var(--bg-color-4);
      border-radius: rem(14);

      &__wrapper {
        padding: rem(15) rem(21) rem(14) rem(21);
      }

      &__header {
        margin-bottom: rem(9);

        & > .svg-container {
          margin-right: rem(11);
        }
      }

      &__heading {
        @include font(18, 24, 0.54);
        font-weight: 800;
      }

      &__content {
        @include font(14, 20);
        padding: 0 rem(2);
        opacity: 0.75;
      }
    }

    &__content {
      @include media(">=md") {
        padding: 0 rem(2);
      }
    }

    &__cards {
      @include media(">=md") {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .card {
        max-width: rem(328);
        background-color: transparent;
        margin: 0 auto rem(60);

        &:last-of-type {
          margin-bottom: 0;
        }

        @include media(">=md") {
          flex: 1 1 50%;
          max-width: calc(50% - rem(16));
          margin: 0 0 rem(32);
        }

        @include media(">=xl") {
          flex: 1 1 33%;
          max-width: calc(33% - rem(16));
          margin: 0;
        }

        &__header {
          margin-bottom: rem(21);

          & > .svg-container {
            min-width: rem(28);
            margin-right: rem(8);
            opacity: 0.75;
          }
        }

        .btn {
          display: inline-flex;
          @include font(13, 15.6, 0.78);
          font-weight: 700;
          padding: rem(10) rem(15) rem(9) rem(15);
          margin-left: auto;

          &-secondary {
            border-color: rgba(255, 255, 255, 0.35);
          }
        }

        &__title {
          @include font(22, 26, 0.66);
          font-weight: 900;
        }

        &__details {
          background-color: var(--bg-color-2);
          border-radius: rem(14);

          @include media(">=md") {
            height: 100%;
          }

          @include media(">=xl") {
            height: auto;
          }

          &__wrapper {
            padding: rem(24);
          }

          &__info {
            margin-bottom: rem(8);

            & > svg {
              position: relative;
              top: rem(-3);
              min-width: rem(24);
            }

            & > span {
              @include font(14, 20);
              font-weight: 500;

              &:first-of-type {
                @include font(14, 20, 0.56);
                font-weight: 800;
                min-width: rem(100);
              }
            }
          }
        }

        &__heading {
          @include font(18, 24, 0.54);
          font-weight: 800;
          margin-bottom: rem(17);
        }

        &__list {
          margin-bottom: rem(15);

          & > li {
            @include font(14, 20);
            font-weight: 500;
            padding-left: rem(16);
            margin-bottom: rem(6);

            &:last-of-type {
              margin-bottom: 0;
            }

            & > .svg-container {
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }

        &.account-goal {
          .goal {
            &-icon {
              top: rem(22);
              right: rem(24);
            }

            &-scores {
              border: rem(2) solid rgba(255, 255, 255, 0.25);
              border-radius: rem(14);

              &__wrapper {
                padding: rem(15) rem(14) rem(11);

                & > .card__details__info {
                  margin-bottom: rem(2);
                }
              }
            }
          }

          .card {
            &__list {
              & > li {
                & > span {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        &.account-plan {
          strong {
            @include font(14, 20);
            font-weight: 800;
            margin-bottom: rem(11);
          }

          .card {
            &__header {
              margin-bottom: rem(24);
            }

            &__details {
              &__wrapper {
                padding: rem(24) rem(16) rem(31) rem(16);
              }
            }

            &__list {
              .svg-container {
                color: var(--primary-green);
              }

              & > li {
                padding-left: rem(32);
                margin-bottom: rem(12);

                &:last-of-type {
                  margin-bottom: rem(28);
                }
              }
            }
          }
        }

        &.account-body {
          .card {
            &__details {
              &__wrapper {
                padding: rem(27) rem(16) rem(13);
              }

              &__info {
                @include media(">=lg") {
                  flex-wrap: wrap;
                }

                @include media(">=xxl") {
                  flex-wrap: nowrap;
                }

                & > span {
                  &:first-of-type {
                    margin-left: rem(8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.loader__wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
