@import "/src/assets/css/style.scss";

.contact-form {
  &__wrapper {
    padding: rem(64) 0;

    @include media(">=md") {
      padding: rem(94) 0 rem(124);
    }

    @include media(">=xl") {
      display: flex;
      justify-content: center;
    }
  }

  .form {
    max-width: rem(660);
    min-height: rem(964);
    border-radius: rem(14);
    box-shadow: rem(3) rem(23) rem(23) 0px rgba(0, 0, 0, 0.35);
    background-color: var(--bg-color-2);
    margin: 0 auto rem(24);

    @include media(">=xl") {
      flex: 1 1 50%;
      min-height: rem(784);
      max-width: calc(67% - rem(14.47));
      margin: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__inner {
      padding: rem(33) rem(10) rem(32) rem(13);

      @include media(">=sm") {
        padding: rem(31) rem(10) rem(10) rem(32);
      }
    }

    &__avatar {
      max-width: rem(70);
      margin-right: rem(13);

      @include media(">=sm") {
        max-width: rem(104);
        margin-right: rem(28);
      }
    }

    &__details {
      padding-top: rem(3);

      @include media(">=sm") {
        padding-top: rem(8);
      }

      & > .heading[data_set="h3"] {
        @include font(18, 20, 0.54);
        font-weight: 900;

        @include media(">=sm") {
          @include font(22, 26, 0.66);
        }
      }
    }

    &__wrapper {
      margin-top: rem(36);

      &_group {
        display: flex;
        flex-direction: column;
        gap: rem(20);

        .form-group {
          width: 100%;
        }

        @include media(">=lg") {
          flex-direction: row;
        }
      }

      .btn_wr {
        margin-top: rem(36);

        button {
          width: 100%;
        }

        @include media(">=lg") {
          width: rem(310);
        }
      }
    }

    &__contact {
      margin-bottom: rem(9);

      @include media(">=sm") {
        margin-bottom: rem(8);
      }

      & > a {
        color: var(--color-white);
        opacity: 0.75;
        transition: opacity 300ms ease-out;

        &:hover {
          opacity: 1;
        }
      }
    }

    &__social {
      & > a {
        @include font(16, 24, 0);
        font-weight: 500;
        color: var(--color-white);
        opacity: 0.75;
        transition: opacity 300ms ease-out;

        &:hover {
          opacity: 1;
        }

        & > span {
          margin-left: rem(8);
        }
      }
    }
  }
}
