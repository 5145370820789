@import "/src/assets/css/style.scss";

.goals {

    @include media('>=md') {
        margin-bottom: rem(64);
    }

    .card {
        flex: 1 100%;
        max-width: rem(392);
        background-color: var(--bg-color-2);
        margin-bottom: rem(24);
        border-radius: rem(14);
        box-shadow: rem(3) rem(23) rem(48) 0 rgba(0, 0, 0, 0.35);
        cursor: pointer;
        transition: box-shadow 100ms ease-out;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: rem(14);
            background-color: transparent;
            opacity: 0;
            transition: opacity 100ms ease-out;
        }

        &::before {
            border: rem(2) solid var(--third-red);
        }

        &::after {
            box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.40);
            backdrop-filter: blur(rem(30));
            z-index: -1;
        }

        &:hover {

            &::before {
                opacity: 1;
            }
        }

        &.is-active {
            box-shadow: none;

            &::before,
            &::after {
                opacity: 1;
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media('>=md') {
            max-width: rem(317);
            margin-left: rem(24);

            &:first-of-type {
                margin-left: 0;
            }
        }

        @include media('>=xl') {
            margin-bottom: 0;
        }

        &-body {
            padding-top: rem(24);
            padding-right: rem(23);
            padding-left: rem(23);
            padding-bottom: rem(17);

            @include media('>=md') {
                padding-left: rem(24);
            }
        }

        &-header {
            padding-right: rem(1);
            padding-left: 0;
            margin-bottom: rem(17)
        }

        &-title {
            @include font(22, 26, 0.66);
            font-weight: 900;
        }

        &-list {
            list-style: none;
            padding: 0 rem(30) 0 0;
            margin: 0;

            @include media('>=md') {
                padding: 0 rem(19) 0 0;
            }

            li {
                @include font(14, 20);
                font-weight: 500;
                margin-bottom: rem(6);

                & > .svg-container {
                    top: 50%;
                    transform: translateY(calc(-50% - rem(1)));
                }

                & > span {
                    padding-left: rem(16);
                }

                strong {
                    font-weight: 700;
                }
            }
        }
    }
}
