@import "/src/assets/css/style.scss";

.terms {

    &__wrapper {
        padding: rem(32) 0 rem(120);
    }

    &__header {
        margin-bottom: rem(52);

        & > .heading[data_set=h1] {
            @include font(16, 24, 3.2);
            font-family: 'BarlowCondensed';
            font-weight: 700;
            color: var(--primary-blue);
            margin-bottom: rem(7);
        }

        & > .heading[data_set=h2] {
            @include font(40, 48, 1.2);
            font-weight: 900;
            margin-bottom: 0;

            @include media('>=md') {
                @include font(56, 68, 1.68);
            }
        }
    }

    &__content {
        max-width: rem(924);
        margin: 0 auto rem(64);

        & > ol {
            padding: 0 rem(16);

            @include media('>=md') {
                padding: 0 rem(18) 0 rem(40);
            }

            & > li {
                @include font(16, 24);
                font-weight: 500;
                padding-left: rem(2);
                margin-bottom: rem(16);

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__footer {

        & > a {
            @include font(17, 20.4, 0.85);
            font-weight: 800;
            color: var(--color-white);
            border-radius: rem(8);
            background-color: transparent;
            padding: rem(16) rem(5) rem(16) rem(16);
            transition: color, background-color 300ms ease-out;

            &::before,
            &::after {
                content: '';
                position: absolute;
                border-radius: rem(8);
            }

            &::before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--bg-color);
                z-index: 2;
                transition: background-color 300ms ease-out;
            }

            &::after {
                top: rem(-2);
                left: rem(-4);
                width: calc(100% + rem(8));
                height: calc(100% + rem(6));
                background-color: var(--color-white);
                opacity: .35;
                z-index: 1;
            }

            & > .svg-container {
                z-index: 3;

                & > svg {
                    min-width: rem(20);

                    & > path {
                        transition: stroke 300ms ease-out;
                    }
                }

                & > span {
                    margin: 0 rem(10);
                }
            }

            &:hover {
                color: var(--bg-color);

                &::before {
                    background-color: var(--color-white);
                }

                & > .svg-container > svg > path {
                    stroke: var(--bg-color);
                }
            }
        }
    }
}
