@import "/src/assets/css/style.scss";

.day_wr {
  padding: rem(20) rem(14);
  border-radius: rem(14);
  border: rem(2) solid var(--gray-50);
  margin-bottom: rem(16);

  &.open {
    border-color: var(--primary-red);
    background-color: var(--bg-color-1);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .day__number {
      @include font(22, 26);
      font-weight: 900;
      font-style: italic;
    }

    .toggle {
      display: flex;
      min-width: rem(70);
      justify-content: center;
      margin-left: rem(8);
      align-items: center;
      cursor: pointer;

      svg {
        transform: rotate(90deg);
      }

      &.open {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .exercise_wr {
    margin-top: rem(12);
  }

  .bold {
    font-weight: 800;
    @include font(20, 24);
  }

  .indent {
    padding-left: rem(16);
  }
  ul {
    list-style-type: none;
    padding: 0;

    li {
      display: flex;
      flex-direction: column;
      padding-left: rem(16);
    }
  }
}
