@import "/src/assets/css/style.scss";

.heading_route {
  font-weight: 900;
  font-style: italic;
  text-align: center;
  @include font(28, 32, 0.84);

  @include media(">=lg") {
    text-align: left;
  }
}
