@import "/src/assets/css/style.scss";

.animated-arrows {
    opacity: .75;

    & > svg {

        & > path {
            animation: fadeInOut 1s infinite;

            &:nth-child(1) {
                animation-delay: .2s;
            }

            &:nth-child(2) {
                animation-delay: .3s;
            }

            &:nth-child(3) {
                margin-right: 0 !important;
                animation-delay: .5s;
            }
        }
    }
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: .15;
    }
    50% {
        opacity: 1;
    }
}
