@import "/src/assets/css/style.scss";

.day__wrapper {
  padding: rem(20) rem(14);
  border-radius: rem(14);
  border: rem(2) solid var(--gray-50);
  margin-bottom: rem(16);

  .day__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .day__number {
      @include font(22, 26);
      font-weight: 900;
      font-style: italic;
    }

    .toggle {
      cursor: pointer;

      svg {
        transform: rotate(90deg);
      }

      &.open {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .day__body {
    display: flex;
    flex-direction: column;
    padding-top: rem(8);

    @include media(">=lg") {
      flex-direction: row;
      flex-wrap: wrap;
    }

    &-icons {
      display: flex;
      justify-content: center;
      margin-top: rem(24);

      svg {
        margin-right: rem(12);
      }

      @include media(">=lg") {
        margin-top: rem(16);
        margin-right: rem(42);
      }

      & + p {
        text-align: center;
        opacity: .75;

        @include media(">=lg") {
          text-align: left;
        }
      }
    }

    &-paragraph {
      @include font(16, 24, 0.16);
      font-weight: 500;
      text-align: center;
      margin-top: rem(16);
      opacity: .75;

      span {
        font-weight: 900;
      }

      @include media(">=lg") {
        text-align: left;
      }

      & > p {
        max-width: rem(659);
        margin: 0 auto rem(24);

        @include media(">=lg") {
          max-width: 100%;
        }
      }
    }

    .day__body-meals_wr {
      width: 100%;
      flex-grow: 1;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &.open {
    border-color: transparent;
    background-color: var(--bg-color);
    padding-bottom: rem(44);
  }
}
