@import "/src/assets/css/style.scss";

.modal {
  &-dialog {
    max-width: 100%;
    margin: 0;

    @include media(">=md") {
      max-width: 80%;
      margin: rem(48) auto;
    }

    @include media(">=lg") {
      max-width: calc(62% - rem(4.8));
    }

    @include media(">=xxl") {
      max-width: rem(888);
    }
  }

  &-content {
    border: none;
    border-radius: 0;
    overflow: hidden;

    @include media(">=md") {
      border-radius: rem(14);
    }
  }

  &-wrapper {
    padding: rem(11) rem(6) rem(34);
  }

  &-header {
    padding: 0 rem(16) rem(42) rem(16);
    padding-bottom: rem(42);
    margin-bottom: rem(20);

    @include media(">=md") {
      padding: 0 rem(42) rem(42) rem(42);
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: rem(42);
      width: calc(100% - rem(84));
      min-height: rem(2);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 50%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.16;
    }

    & > ul {
      opacity: 0.75;

      & > li {
        @include font(16, 14, 0.16);
        position: relative;
        padding-right: rem(19);
        margin-right: rem(17);

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          min-width: rem(2);
          height: 100%;
          background-color: var(--color-white);
        }

        &:last-of-type {
          padding-right: 0;
          margin-right: 0;

          &::after {
            content: none;
          }
        }
      }
    }
  }

  &-title {
    @include font(28, 32, 0.84);
    font-weight: 900;
    margin-bottom: rem(21);
  }

  &-content {
    background-color: var(--bg-color);
  }

  &-body {
    padding: rem(16);

    @include media(">=md") {
      padding: rem(16) rem(42);
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-group {
        flex: 1 1 100%;

        &--half {
          @include media(">=md") {
            flex: 1 1 50%;
            max-width: calc(50% - rem(32));
          }

          .form-label {
            width: 100%;
          }

          .form-group__dates {
            display: flex;
            justify-content: space-between;
            height: 100%;
          }

          .form-field--date {
            position: relative;
            display: flex;
            align-items: center;
            flex: 1 1 50%;
            max-width: calc(50% - rem(12));

            & > span {
              @include font(14, 20, 0.56);
              font-weight: 800;
              margin-right: rem(12);

              & + div {
                height: 100%;
                padding: rem(4) 0;

                .form-control {
                  flex: 1 1 33%;
                  max-width: calc(33% - rem(6.22));
                  border-radius: rem(8);

                  &:last-of-type {
                    max-width: rem(52);
                  }
                }

                .form-control,
                .form-control::placeholder {
                  @include font(14, 20);
                  font-weight: 500;
                  text-align: center;
                  color: var(--color-white);
                }
              }
            }

            & > input {
              max-width: rem(40);

              &:last-of-type {
                max-width: rem(52);
              }
            }

            .react-datepicker-wrapper {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }

      &-group:not(.form-group--half) {
        &[role="group"] {
          margin-bottom: rem(36);

          &:last-of-type {
          }

          & > label {
            @include font(16, 24);
            font-weight: 500;
            padding-left: rem(11);
            margin-bottom: rem(16);
          }

          & > div {
            padding: rem(7) rem(16) rem(9);
            margin-right: rem(9);
            border-radius: rem(24);
            background-color: var(--bg-color-2);

            &:last-of-type {
              margin-right: 0;
            }
          }

          input[type="radio"] {
            display: none;

            & + label {
              @include font(14, 20);
              font-weight: 500;
              position: relative;
              padding-left: rem(32);
              margin-bottom: 0;
              cursor: pointer;

              &::before,
              &::after {
                content: "";
                position: absolute;
                border-radius: 50%;
              }

              &::before {
                top: calc(50% - rem(12));
                left: 0;
                min-width: rem(24);
                min-height: rem(24);
                background-color: var(--bg-color-1);
              }

              &::after {
                top: calc(50% - rem(7));
                left: rem(5);
                min-width: rem(14);
                min-height: rem(14);
                border-radius: 50%;
                background-color: var(--primary-red);
                opacity: 0;
                transition: opacity 0.2s ease-out;
              }
            }

            &:checked {
              & + label {
                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }

        .form-field {
          padding: rem(12) rem(16);
        }
      }

      &-field {
        padding: rem(13) rem(16) rem(12);

        &--media {
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(255, 255, 255, 0.25)' strokeWidth='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e");
          border-radius: 8px;
          background-color: transparent;
          padding: rem(32) rem(30) rem(30) !important;
          margin-bottom: rem(38) !important;

          & > span {
            @include font(14, 20);
            font-weight: 400;
            text-align: center;

            &:first-of-type {
              margin-bottom: rem(7);
            }
          }

          & > button {
            @include font(13, 15.6, 0.78);
            font-style: italic;
            font-weight: 700;
            border-radius: rem(8);
            border-top: rem(2) solid rgba(255, 255, 255, 0.35);
            border-right: rem(4) solid rgba(255, 255, 255, 0.35);
            border-bottom: rem(4) solid rgba(255, 255, 255, 0.35);
            border-left: rem(4) solid rgba(255, 255, 255, 0.35);
            padding: rem(10) rem(24);
            margin-bottom: rem(7);
          }
        }

        .form-button {
          @include font(16, 24, 0.16);
          font-weight: 600;
          padding: rem(8) rem(6) !important;
        }

        & > textarea {
          padding-top: rem(1);
          padding-bottom: rem(10);
        }
      }

      &-control {
        background-color: var(--bg-color-5);
      }

      &-control,
      &-control::placeholder {
        @include font(16, 24);
        font-weight: 500;
      }

      .form__images {
        display: flex;
        flex-wrap: wrap;

        .img_preview {
          width: rem(100);
          height: auto;
          width: fit-content;
          margin-bottom: rem(8);
          border-radius: rem(8);
          position: relative;
          margin-right: rem(8);

          img {
            width: rem(200);
            height: rem(100);
            object-fit: cover;
            border-radius: rem(8);
            margin-left: auto;
          }

          svg {
            background-color: gray;
            border-radius: 50%;
            padding: rem(2);
            position: absolute;
            right: rem(8);
            top: rem(8);
            cursor: pointer;

            path {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &-submit {
    position: relative;
    padding-top: rem(36);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: rem(42);
      width: calc(100% - rem(84));
      min-height: rem(2);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 50%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.16;
    }

    & > .btn {
      @include font(15, 18, 0.6);
      padding: rem(12) rem(48);
    }
  }
}
