@import "/src/assets/css/style.scss";

.message_wr_admin {
  margin-top: rem(20);
  height: 100%;

  // @include media(">=xl") {
  //   margin-top: 0;
  // }

  .message_content_admin {
    height: 100%;
    max-height: rem(500);
    display: flex;
    flex-direction: column;

    &-list {
      background-color: var(--bg-color-1);
      overflow-y: scroll;
      height: rem(390);
      list-style-type: none;
      padding: rem(12);
      border-top-left-radius: rem(12);
      border-top-right-radius: rem(12);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin: 0;

      li {
        margin: rem(4) 0;
        padding: rem(8) rem(12);
        width: fit-content;
        border-radius: rem(4);
        text-align: left;

        @include media(">=sm") {
          max-width: 70%;
        }

        @include media(">=xl") {
          max-width: 60%;
        }

        &.message__author {
          // text-align: right;
          margin-left: auto;
          background-color: var(--primary-blue);
        }

        &.message__caller {
          text-align: left;
          background-color: var(--bg-color-7);
        }
      }
    }

    .message__form_wrapper {
      border-top: rem(1) solid var(--gray-1);

      .form-field {
        border-radius: 0;
        border-bottom-left-radius: rem(12);
        border-bottom-right-radius: rem(12);
      }

      .send_button {
        width: 100%;

        // @include media("=>xl") {
        //   width: rem(200);
        //   margin-left: auto;
        // }
      }
    }
  }

  .cap {
    border-radius: rem(12);
    width: 100%;
    height: 100%;
    min-height: rem(500);
    background-color: var(--bg-color-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: rem(100);
      height: auto;
    }
  }
}
