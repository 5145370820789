@import "/src/assets/css/style.scss";

.dashboard {
  &__diets {
    .section__user {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include media(">=lg") {
        flex-direction: row;
      }

      .user__info {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media(">=lg") {
          justify-content: flex-start;
          align-items: flex-start;
          margin-right: rem(64);
        }

        .btn_wrapper {
          margin-top: rem(16);
          width: rem(148);
          height: rem(36);
        }

        .diets-btn {
          width: rem(148);
          text-align: center;
          margin: rem(18) auto 0 auto;
          @include font(13);
          display: flex;
          justify-content: center;
          padding: rem(10) 0;
          box-shadow: 0px 4px 64px 0px rgba(251, 72, 72, 0.4);
          backdrop-filter: blur(30px);

          @include media(">=lg") {
            margin-right: 0;
            margin-left: 0;
          }
        }

        .user__item {
          margin-top: rem(-2);
          text-align: center;
          white-space: nowrap;

          @include media(">=lg") {
            display: flex;
            align-items: center;
          }

          span {
            font-weight: 900;
            @include font(14, 20, 0.56);
            text-transform: uppercase;
            font-style: italic;

            @include media(">=lg") {
              margin-right: rem(8);
            }
          }
        }
      }

      .user__demands {
        padding: rem(16);
        padding-bottom: rem(24);
        background-color: var(--bg-color-1);
        border-radius: rem(14);
        margin-top: rem(58);
        margin-bottom: rem(64);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include media(">=lg") {
          margin-top: 0;
          flex-direction: row;
          max-width: rem(568);
          justify-content: center;
        }

        &-nutritions {
          margin-top: rem(16);
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          gap: rem(8);

          @include media(">=lg") {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            width: 100%;
          }

          &-item {
            padding: rem(16) 0;
            border-radius: rem(8);
            border: rem(2) solid var(--bg-color-2);

            display: flex;
            flex-direction: column;
            align-items: center;
            gap: rem(12);

            &-cat {
              @include font(16, 24);
              color: var(--gray-75);
            }

            &-value {
              @include font(28, 32, 0.84);
              text-align: center;
              font-style: italic;
              font-weight: 900;
              color: #ffac33;

              & > input {
                display: inline-block;
                @include font(28, 32, 0.84);
                font-weight: 900;
                font-style: italic;
                text-align: center;
                width: 80%;
                border: none;
                padding: 0;
                margin-right: rem(-15);
                color: #ffac33;
                background-color: transparent;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                  -webkit-appearance: inner-spin-button !important;
                  opacity: 1 !important;
                }

                &:focus {
                  background-color: var(--bg-color);
                }

                &:nth-child(2) {
                  color: #d7ffbe;
                }

                &:nth-child(3) {
                  .user__demands-nutritions-item-value {
                    color: #86cbff;
                  }
                }

                &:nth-child(4) {
                  .user__demands-nutritions-item-value {
                    color: #ffc0a1;
                  }
                }
              }
            }

            &:nth-child(2) {
              .user__demands-nutritions-item-value {
                color: #d7ffbe;

                & > input {
                  color: #d7ffbe;
                }
              }
            }

            &:nth-child(3) {
              .user__demands-nutritions-item-value {
                color: #86cbff;

                & > input {
                  color: #86cbff;
                }
              }
            }

            &:nth-child(4) {
              .user__demands-nutritions-item-value {
                color: #ffc0a1;

                & > input {
                  color: #ffc0a1;
                }
              }
            }
          }
        }

        &-icon-wr {
          width: 100%;
          display: flex;
          justify-content: center;

          @include media(">=lg") {
            width: fit-content;
            align-self: flex-start;
            margin-left: rem(16);
          }
        }

        &-paragraph {
          margin-top: rem(2);
          display: flex;
          flex-direction: column;
          @include font(18, 20, 0.54);
          font-weight: 900;
          font-style: italic;
          margin-bottom: rem(16);
          text-align: center;

          @include media(">=lg") {
            @include font(18, 24, 0.54);
            flex-direction: row;
            white-space: nowrap;
            margin-bottom: rem(2);
          }

          & > span {
            display: inline-block;
            color: var(--color-white);
            margin-bottom: rem(8);
            -webkit-text-fill-color: var(--bg-color);
            -webkit-text-stroke-width: rem(1.5);
            -webkit-text-stroke-color: var(--color-white);

            @include media(">=md") {
              margin-bottom: rem(0);
              margin-right: rem(6);
            }
          }
        }
      }
    }
  }

  .section__creator {
    background-color: var(--bg-color-2);
    border-radius: rem(14);
    padding: rem(16) rem(16);
    margin-bottom: rem(48);

    .creator__example {
      text-align: center;
      opacity: .75;

      @include media(">=lg") {
        text-align: left;
      }
    }

    .creator__notes {
      margin-top: rem(36);
      opacity: .75;
    }

    .creator__days {
      padding-top: rem(32);
    }

    .creator__buttons {
      display: flex;
      flex-direction: column;
      margin-top: rem(48);

      .btn-primary {
        white-space: nowrap;
        padding-right: 0;
        padding-left: 0;

        a {
          color: var(--color-white);
          text-decoration: none;
          margin-right: rem(10);
          height: 100%;
        }

        @include media(">=md") {
          padding: rem(12) rem(48);
          margin-top: rem(4);
          height: fit-content;
        }
      }

      .btn-secondary {
        display: flex;
        justify-content: center;
        margin-top: rem(24);

        span {
          margin-right: rem(16);
        }

        @include media(">=md") {
          margin-top: rem(0);
        }
      }

      @include media(">=md") {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    h3 {
      margin-top: rem(8);
      @include font(28, 22, 0.68);
      font-weight: 900;
      font-style: italic;
      text-align: center;
      margin-bottom: 0;

      @include media(">=lg") {
        text-align: left;
      }

      & > span {
        width: 100%;
        display: inline-block;
        color: var(--color-white);
        margin-bottom: rem(12);
        -webkit-text-fill-color: var(--bg-color);
        -webkit-text-stroke-width: rem(1.5);
        -webkit-text-stroke-color: var(--color-white);

        @include media(">=lg") {
          width: fit-content !important;
        }
      }
    }

    .creator__options {
      display: flex;
      flex-direction: column;
      border-bottom: rem(1) solid var(--gray-1);
      padding-bottom: rem(30);

      @include media(">=lg") {
        flex-direction: row;
      }

      &-days {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: rem(32);
        margin-right: rem(64);

        @include media(">=lg") {
          flex-direction: column;
          align-items: flex-start;
        }

        &-label {
          @include font(14, 20, 0.56);
          font-weight: 900;
          font-style: italic;

          @include media(">=lg") {
            margin-bottom: rem(12);
          }
        }

        &-input {
          display: flex;
          justify-content: center;
          align-items: center;

          input[type="number"] {
            -moz-appearance: textfield;
            appearance: textfield;
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          input {
            border-radius: rem(8);
            padding: rem(12) rem(16);
            -webkit-appearance: none;
            width: rem(64);
            height: rem(52);
            text-align: center;
            background-color: var(--bg-color-1);
            outline: none;
            border: none;
            color: white;
            margin: 0 rem(8);
            font-weight: 600;
          }

          .down,
          .up {
            cursor: pointer;
            width: rem(40);
            height: rem(40);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--gray-1);
          }

          .down {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }

      &-meals {
        margin-top: rem(32);
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(">=lg") {
          flex-direction: column;
          align-items: flex-start;
        }

        &-label {
          @include font(14, 20, 0.56);
          display: block;
          font-weight: 800;
          font-style: italic;
          max-width: 60px;
          white-space: wrap;

          @include media(">=lg") {
            max-width: 100%;
          }
        }

        &-number {
          border-radius: rem(8);
          width: rem(168);
          height: rem(52);
          background-color: var(--bg-color-1);
          display: flex;
          overflow-x: scroll;
          padding: rem(6);

          .meals_number-item {
            font-weight: 600;
            min-width: rem(52);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: rem(8);

            &.active {
              background-color: var(--primary-red);
            }
          }
        }
      }
    }
  }
}
