@import "/src/assets/css/style.scss";

.hero-contact {

    &__button {
        max-width: rem(388);
        border-radius: rem(14);
        margin: 0 auto;
        overflow: hidden;

        & > a {
            @include font(16, 24);
            font-weight: 700;
            color: var(--color-white);
            width: 100%;
            border-top: rem(2) solid var(--primary-red);
            border-right: rem(4) solid var(--primary-red);
            border-bottom: rem(4) solid var(--primary-red);
            border-left: rem(4) solid var(--primary-red);
            border-radius: rem(14);
            background-color: var(--bg-color);
            padding: rem(23) rem(10) rem(21) rem(19);
            transition: backdrop-filter, box-shadow, background-color 300ms ease-out;

            &:hover {
                backdrop-filter: blur(30px);
                box-shadow: 0px 4px 64px 0px rgba(251, 72, 72, 0.40) !important;
                background-color: var(--primary-red);

                & > svg {

                    & > path {
                        fill: var(--color-white);
                    }
                }
            }

            @include media('>=md') {
                @include font(18, 26);
                padding: rem(22) rem(13) rem(19) rem(32);
            }

            & > svg {
                max-width: rem(20);

                & > path {
                    transition: fill 300ms ease-out;
                }

                @include media('>=md') {
                    max-width: rem(24);
                }
            }

            & > span {
                margin-left: rem(16);
            }
        }
    }
}

.hero-contact {

    &__wrapper {
        padding: rem(134) 0;
        overflow: hidden;

        @include media('>=md') {
            padding: rem(39) 0 rem(46);
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            min-height: rem(139);
            background-color: var(--primary-red);
            mix-blend-mode: lighten;
            filter: blur(rem(91));
            opacity: .2;
            z-index: -1;

            @include media('>=md') {
                width: auto;
                min-width: rem(620);
            }
        }

        &::before {
            top: 0;
            background-color: var(--primary-blue);

            @include media('>=md') {
                top: rem(-38);
                left: rem(-213);
            }
        }

        &::after {
            @include media('>=md') {
                right: rem(-221);
                bottom: rem(-29);
            }
        }

        .pulse {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: rem(270);
            mix-blend-mode: multiply;

            @include media('>=md') {
                transform: translateX(0);
            }

            &:nth-child(1) {
                top: rem(-46);
                left: calc(50% + rem(32));

                @include media('>=md') {
                    top: 0;
                    left: rem(3);
                }
            }

            &:nth-child(2) {
                bottom: rem(-34);

                @include media('>=md') {
                    right: 0;
                    bottom: 0;
                    left: auto;
                }
            }
        }
    }

    &__content {
        position: relative;
        max-width: rem(328);
        margin: 0 auto;
        z-index: 2;

        @include media('>=md') {
            max-width: 100%;
            padding: 0 rem(48);
        }

        & > span {
            @include font(16, 24, 3.2);
            font-family: BarlowCondensed;
            font-weight: 700;
            color: var(--primary-red);
            margin-bottom: rem(13);

            @include media('>=md') {
                margin-bottom: rem(7);
            }
        }

        & > .heading[data_set=h1] {
            @include font(40, 48, 1.2);
            font-weight: 900;
            margin-bottom: rem(23);

            @include media('>=md') {
                @include font(56, 68, 1.68);
                margin-bottom: rem(20);
            }

            & > span {
                @include font(40, 48, 1.2);
                -webkit-text-fill-color: transparent;

                @include media('>=md') {
                    @include font(56, 68, 1.68);
                }
            }
        }

        & > p {
            @include font(16, 24);
            font-weight: 500;
            max-width: rem(658);
            margin: 0 auto rem(33);
            opacity: .8;

            @include media('>=md') {
                margin: 0 auto rem(49);
            }
        }
    }
}
