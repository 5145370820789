@import "/src/assets/css/style.scss";

.dashboard__sidebar {
  position: relative;
  flex-direction: column;
  padding: 0 rem(16) rem(25);
  background-color: var(--bg-color);
  margin-top: rem(16);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: calc(100% - rem(36));
    transform: translateX(-50%);
    min-height: rem(2);
    opacity: 0.16;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );

    @include media(">=lg") {
      content: none;
    }
  }

  &.show {
    background-color: var(--bg-color-2);
    height: 100%;

    &::after {
      opacity: 0;
    }
  }

  .dashboard__nav_toggle {
    cursor: pointer;
    border-radius: rem(8);
    box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);
    backdrop-filter: blur(rem(30));
    background-color: var(--primary-red);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include font(14, 16.8, 0.56);
    font-weight: 600;
    text-decoration: none;
    color: var(--color-white);
    padding: rem(10) rem(12);
    border-radius: rem(8);

    &.open {
      margin-bottom: rem(8);
    }

    span {
      height: 100%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }

    svg {
      margin-right: rem(12);
    }

    &-chevron {
      transition: 0.3s;
      svg {
        transform: rotate(90deg);
        margin-right: 0;
      }

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .dashborad__nav {
    overflow: hidden;
    height: 0;

    &.show {
      height: 100%;
    }
  }

  .sidebar-nav-wr {
    padding: 0;

    @include media(">=lg") {
      position: relative;
      padding-bottom: rem(16);
      margin-bottom: rem(16);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: rem(1);
        background-color: var(--color-white);
        opacity: 0.25;
      }

      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;

        &::after {
          content: none;
        }
      }
    }
  }

  li {
    list-style-type: none;

    .btn-logout {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      svg {
        margin-right: rem(12);
      }

      &:hover {
        background-color: var(--primary-red);
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      @include font(14, 16.8, 0.56);
      font-weight: 600;
      text-decoration: none;
      color: var(--color-white);
      padding: rem(10) rem(12);
      border-radius: rem(8);

      span {
        margin-left: rem(12);
      }

      &.active {
        background-color: var(--primary-red);
        // box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.40);
        // backdrop-filter: blur(rem(30));
      }
    }
  }

  @include media(">=lg") {
    background-color: var(--bg-color-2);
    margin-top: rem(78);
    margin-bottom: rem(36);
    border-top-right-radius: rem(14);
    border-bottom-right-radius: rem(14);
    margin-right: rem(76);
    padding: rem(16) rem(16) rem(16) rem(48);
    height: fit-content;

    .dashboard__nav_toggle {
      display: none;
    }

    .dashborad__nav {
      height: 100%;
    }

    li {
      .nav-link {
        width: rem(248);

        &.active {
          span {
            margin-left: rem(24);
          }
        }
      }
    }
  }
}
