@import "/src/assets/css/style.scss";

.achievements {
    padding: rem(64) 0 rem(85);

    &__header {
        text-align: center;
        margin-bottom: rem(49);

        @include media('>=xl') {
            margin-bottom: rem(24)
        }

        .header {

            &__content {
                & > span {
                    @include font(16, 24, 3.2);
                    font-family: BarlowCondensed;
                    font-weight: 700;
                    color: var(--primary-blue);
                    margin-bottom: rem(4);

                    @include media('>=md') {
                        margin-bottom: rem(8);
                    }
                }

                & > h2[data_set=h2] {
                    @include font(32, 42, .96);
                    font-weight: 900;
                    margin-bottom: 0;

                    @include media('>=md') {
                        @include font(42, 56, 1.26);
                    }
                }
            }
        }

        .arrows {
            @include media('>=lg') {
                position: absolute;
                min-width: rem(110.62);
                min-height: rem(99.2);

                &--left {
                    top: rem(-38);
                    left: 19%;

                    .animated-arrows {
                        position: absolute;

                        &:nth-child(1) {
                            top: 0;
                            left: 0;
                        }

                        &:nth-child(2) {
                            top: rem(-2);
                            right: rem(15);
                        }

                        &:nth-child(3) {
                            bottom: rem(-2);
                            right: 50%;
                            transform: translateX(calc(50% - rem(18)));
                        }
                    }
                }

                &--right {
                    top: rem(-58);
                    right: calc(12% - rem(1));
                    min-width: rem(53);
                    min-height: rem(92);

                    .animated-arrows {
                        position: absolute;

                        &:nth-child(1) {
                            top: rem(-2);
                            left: rem(-3);
                        }

                        &:nth-child(2) {
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &__content {

        @include media('>=lg') {
            display: flex;
        }

        @include media('>=xl') {
            justify-content: center;
            // align-items: center;
            max-width: rem(1116);
            margin: 0 auto;
        }
    }

    .achievement {
        max-width: rem(393);
        text-align: center;
        margin: 0 auto rem(75);

        @include media('>=lg') {
            flex: 1;
            max-width: rem(662);
            margin-bottom: 0;
        }

        @include media('>=xl') {
            margin: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &__image {
            width: auto;
            text-align: center;

            & > img {
                max-height: rem(402);

                @include media('>=md') {
                    max-height: 100%;
                }
            }

            & > .border-line {
                position: absolute;
                width: 100%;
                height: 100%;
                top: rem(-13);
                right: 0;
                z-index: -1;

                & > svg {

                    & > path {
                        animation: borderLine 1.5s infinite;

                        &:nth-child(1) {
                            animation-delay: .2s, .2s;
                        }

                        &:nth-child(2) {
                            animation-delay: .3s, .3s;
                        }

                        &:nth-child(3) {
                            margin-right: 0 !important;
                            animation-delay: .5s, .5s;
                        }
                    }
                }
            }
        }

        .achievement__content {
            box-shadow: 0px 4px 64px 0px rgba(33, 122, 255, 0.40);
            backdrop-filter: blur(12px);
        }

        &:nth-child(3) {

            .achievement__image {

                .border-line {
                    width: 128%;
                    top: rem(-27);
                    right: auto;
                    left: rem(-59);

                    @include media('>=lg') {
                        width: auto;
                        top: rem(-40);
                        left: rem(-75);
                    }
                }

                // & > img {
                //     max-height: rem(419);
                // }
            }
        }

        &__content {
            position: relative;
            border-radius: rem(14);
            box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.40);
            background: rgba(20, 23, 28, 0.80);
            backdrop-filter: blur(rem(30));
            padding: rem(26) rem(12) rem(22);
            margin-top: rem(-150);
            z-index: 2;

            @include media('>=md') {
                padding-top: rem(24);
            }

            @include media('>=xl') {
                padding: rem(24);
                margin-top: 0;
            }

            & > ul {
                padding: 0 rem(13);

                @include media('>=lg') {
                    display: flex;
                }

                @include media('>=xl') {
                    padding: 0;
                }

                & > div {
                    @include media('>=lg') {
                        flex: 1 1 50%;
                    }

                    & > li {
                        margin-bottom: rem(12);

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        & > svg {
                            position: relative;
                            top: rem(4);
                            min-width: rem(20);
                        }

                        & > span {
                            @include font(16, 24);
                            font-weight: 500;
                            margin-left: rem(11);
                        }
                    }
                }
            }
        }

        &__header {
            @include font(14, 20);
            font-weight: 500;
            margin-bottom: rem(17);

            & > .heading[data_set=h3] {
                @include font(24, 22, 0.72);
                font-weight: 900;
                margin-bottom: rem(16);

                @include media('>=md') {
                    @include font(28, 32, 0.84);
                    margin-bottom: rem(17);
                }

                & > span {
                    -webkit-text-fill-color: transparent;
                }
            }

            & > p {
                margin-bottom: rem(22);

                // &:last-of-type {
                //     margin-bottom: 0;
                // }

                @include media('>=xl') {
                    margin-bottom: rem(24);
                }
            }

            & > div {
                @include font(16, 24);
                font-weight: 500;
                justify-content: center;

                & > span {
                    margin-left: rem(12);
                }
            }
        }
    }

    &__background {
        @include media('>=xl') {
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: rem(853);
            z-index: -1;

            &__image {
                position: relative;

                &:nth-child(1) {
                    right: rem(-47);
                    bottom: rem(34);
                    z-index: 2;

                    & > .border-line {
                        position: absolute;
                        top: rem(-19);
                        z-index: -1;
                    }
                }

                &:nth-child(2) {
                    top: rem(-46);
                    left: rem(-46);

                    & > .border-line {
                        top: rem(-41);
                        right: rem(81);

                        & > svg {
                            max-width: unset;
                        }
                    }
                }

                & > .border-line {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: -1;

                    & > svg {

                        & > path {
                            animation: borderLine 1.5s infinite;

                            &:nth-child(1) {
                                animation-delay: .2s;
                            }

                            &:nth-child(2) {
                                animation-delay: .3s;
                            }

                            &:nth-child(3) {
                                margin-right: 0 !important;
                                animation-delay: .5s;
                            }
                        }
                    }
                }
            }

            &__layout {
                bottom: rem(44);
                width: 100%;
                min-height: rem(293.81);
                background: linear-gradient(180deg, rgba(34, 37, 41, 0.00) 0%, #222529 100%);
                z-index: 2;
            }
        }
    }
}

@keyframes borderLine {
    0%, 100% {
        opacity: .15;
    }
    50% {
        opacity: 1;
    }
}

@keyframes hide {
    0%, 33.333% {
        opacity: 1;
    }
    33.333%, 100% {
        opacity: 0;
    }
}
