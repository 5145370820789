@import "/src/assets/css/style.scss";

.section__wrapper {
  margin: rem(42) rem(16) 0 rem(16);
  padding-bottom: rem(86);

  @include media(">=md") {
    margin: rem(48) rem(48);
  }

  .paragraph__workssection {
    @include font(16, 24, 3.2);
    font-family: "BarlowCondensed";
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-blue);
    font-style: italic;
    text-align: center;
    margin-bottom: rem(5);

    @include media(">=md") {
      margin-bottom: rem(8);
    }

    & + .heading[data_set="h2"] {
      @include font(32, 42, 0.96);
      font-weight: 900;

      @include media(">=md") {
        @include font(42, 56, 1.26);
      }

      & + .content__wrapper {
        margin-top: rem(29);

        @include media(">=md") {
          margin-top: rem(37);
        }
      }
    }
  }

  .content__wrapper {
    margin-top: rem(36);

    .description__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: rem(-19);
      z-index: 4;

      @include media(">=sm") {
        margin-top: rem(-38);
      }

      @include media(">=lg") {
        flex-direction: row;

        .single__step {
          margin-bottom: 0;
          margin-right: rem(24);

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @include media(">=xl") {
        margin-top: rem(-141);
      }

      .single__step {
        background-color: var(--bg-color-2);
        border-radius: rem(14);
        margin-bottom: rem(20);
        padding: rem(24) rem(16) rem(23);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        flex: 1 1 0%;

        &:last-child {
          margin-bottom: 0;
        }

        @include media(">=lg") {
          margin-bottom: 0;
        }

        .step__title {
          @include font(18, 24, 0.54);
          font-weight: 800;
          font-style: italic;
          text-align: center;
          margin-top: rem(53);
          margin-bottom: rem(17);
        }

        .step__content {
          @include font(14, 20);
          font-weight: 500;
          text-align: center;
        }

        .step__icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: rem(-10);
          width: rem(84);
          height: auto;
          color: var(--primary-blue);

          svg {
            width: 100%;
          }

          .step__number {
            color: white;
            @include font(32);
            font-weight: 900;
            margin-top: rem(-10);
          }
        }
      }
    }

    .video__wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rem(14) rem(14) 0 0;
      padding-bottom: 56.25%;
      overflow: hidden;

      @include media(">=xl") {
        padding-bottom: calc(56.25% - rem(91));
      }

      &:hover {
        cursor: pointer;

        .video__play {
          opacity: 1;
        }

        .video__background {
          & > div {
            opacity: 1;
          }
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.5s ease;
        cursor: pointer;
      }

      .overlay.pause {
        opacity: 1;
      }

      .button__video {
        color: var(--gray-50);
        text-transform: uppercase;
        font-weight: 700;
        font-style: italic;
        display: flex;
        flex-direction: column;
        position: absolute;

        svg {
          fill: red;
        }
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: opacity, visible 500ms ease-out;

        &.visible {
          opacity: 1;
          visibility: visible;
          z-index: 4;
        }

        @include media(">=xl") {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .video__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 1;
        visibility: visible;
        transition: opacity, visible 500ms ease-out;

        &.hidden {
          opacity: 0;
          visibility: hidden;
        }

        // @include media('>=xl') {

        // }

        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   background-color: var(--bg-color-8);
        //   opacity: 0.85;
        //   mix-blend-mode: multiply;
        //   z-index: 2;
        // }
      }
      .playButton {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        opacity: 0.5;
        transition: opacity 300ms ease-out;

        & > span {
          @include font(17, 20, 0.05);
          font-weight: 800;
          color: var(--color-white);
          margin-top: rem(8);
        }
        &:hover {
          opacity: 1;
        }
      }
      .video-triggers {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 4;
        padding: 0 rem(4);

        @include media(">=sm") {
          padding: 0;
        }

        & > .video-trigger {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1;
          cursor: pointer;

          @include media(">=sm") {
            opacity: 0.75;
            transition: opacity 300ms ease-out;

            &:hover {
              opacity: 1;

              & > svg {
                & > rect {
                  fill: var(--primary-red);
                }
              }
            }
          }

          & > svg {
            max-width: rem(30);

            @include media(">=sm") {
              max-width: rem(37);

              & > rect {
                transition: fill 300ms ease-out;
              }
            }
          }

          & > span {
            @include font(15, 18, 0.6);
            font-weight: 800;
            margin-left: rem(4);

            @include media(">=sm") {
              @include font(28, 32);
              margin-left: rem(11);
            }
          }
        }
      }

      .video__play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - rem(38)));
        opacity: 0.7;
        z-index: 3;
        transition: opacity 300ms ease-out;

        & > .svg-container {
          max-width: rem(41);

          @include media(">=md") {
            max-width: rem(82);
          }
        }

        & > span {
          @include font(14, 20);
          font-weight: 500;
          text-align: center;

          @include media(">=md") {
            @include font(18, 26);
          }
        }
      }
    }
  }
}
