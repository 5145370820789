@import "/src/assets/css/style.scss";

.meal_wrapper {
  border-bottom: rem(1) solid var(--gray-1);
  padding-bottom: rem(40);
  margin-top: rem(32);
  display: flex;
  flex-direction: column;

  @include media(">=lg") {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .meal_header {
    @include font(18, 26);
    width: 100%;
    text-align: center;
    display: block;

    @include media(">=lg") {
      width: rem(96);
      margin-right: rem(16);
    }
  }

  .meal_body {
    display: flex;
    flex-direction: column;

    @include media(">=lg") {
      flex-direction: row;
      width: 100%;
      gap: rem(16);
    }
    .nutrition_wr {
      display: flex;
      flex-direction: column;

      @include media(">=lg") {
        flex: 1 1 0%;
      }

      .nutrition__header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: rem(24) 0 rem(8) 0;

        svg {
          margin-right: rem(10);
        }

        span {
          margin-right: rem(16);
        }
      }
      .meal_select {
        @include font(16, 24);
        white-space: wrap;

        .option {
          white-space: wrap;
          border-top: 1px solid var(--bg-color-3);
          padding: rem(16);
          line-height: 1.2;
        }

        width: 100%;
        height: auto;
        font-weight: 500;
        background-color: var(--bg-color-1);
        border-radius: rem(8);
        border: none;
        padding: rem(12) rem(16);
        padding-right: rem(36);

        &::after {
          content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiNFQkVFRjIiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K");
          top: rem(12);
          right: rem(16);
          min-width: rem(24);
          min-height: rem(24);
          margin: 0;
          transform: none;
          border: none;
        }

        &.open {
          border-radius: rem(8) rem(8) 0 0;
        }

        .nice-select {
          &-dropdown {
            width: 100%;
            margin-top: 0;
            border-radius: 0 0 rem(8) rem(8);
            background-color: var(--bg-color-2);

            .list {
              border-radius: 0;
              margin-bottom: 0;

              .option {
                background-color: var(--bg-color-2);

                &:hover,
                &.focus,
                &.selected.focus {
                  background-color: var(--bg-color-5);
                }
              }
            }
          }
        }
      }
    }
  }
}
