@import "/src/assets/css/style.scss";

.breadcrumps__wrapper {
  width: 100%;
  margin-top: rem(16);
  display: flex;
  justify-content: center;
  @include font(13, 15, 0.26);
  font-weight: 500;

  .current_path {
    margin: 0 rem(8);
    cursor: pointer;
  }

  a {
    text-decoration: none;
    margin-left: rem(8);
    color: var(--gray-50);

    &.active {
      color: var(--color-white);
    }

    svg {
      margin-left: rem(4);
      width: rem(16);
      height: rem(16);

      path {
        stroke: var(--gray-50);
      }
    }
  }

  @include media(">=lg") {
    position: absolute;
    top: rem(116);
    margin-top: rem(28);
  }
}
