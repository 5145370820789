@import "/src/assets/css/style.scss";

.package_item {
  display: grid;
  grid-template-areas:
    "head"
    "img"
    "content";
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;

  @include media(">=xl") {
    column-gap: rem(64);
    grid-template-areas:
      "img head"
      "img content";
    grid-template-rows: auto 1fr;
    grid-template-columns: max-content 1fr;
    margin-bottom: rem(128);

    &:nth-child(even) {
      grid-template-areas:
        "head img"
        "content img";
      grid-template-columns: 1fr max-content;

      .package_item__image {
        @include media(">=xl") {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          width: 100%;
        }
      }
    }
  }

  &__content {
    position: relative;
    grid-area: content;
    display: flex;
    flex-direction: column;
    margin-bottom: rem(56);
    @include font(18, 26);

    .target {
      margin-top: rem(8);
    }

    &-desc {
      margin: rem(36) 0;
      color: var(--gray-75);
      @include font(16, 24);
    }

    &-transaction {
      display: flex;
      flex-direction: column;
      padding-bottom: rem(56);

      .btn_wr {
        display: flex;
        justify-content: center;

        a {
          width: rem(300);
        }
      }

      .price {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: rem(44);
        margin-bottom: rem(24);

        span {
          @include font(34, 30, 0.82);
          margin-left: rem(8);
          font-style: italic;
          font-weight: 900;
        }
      }

      @include media(">=xl") {
        flex-direction: row;
        align-items: center;
        padding: 0;
        margin-top: rem(36);

        .price {
          margin: 0;
          margin-right: rem(48);
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        @include font(16, 24, 0.16);
        margin-bottom: rem(12);

        .icon_wr {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: rem(12);

          svg {
            display: block;
            min-width: rem(20);
            min-height: rem(20);
            path {
              stroke: #50dc5e;
            }
          }
        }

        &.strong {
          font-weight: 800;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: rem(2);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 50%,
        rgba(255, 255, 255, 0) 100%
      );
      opacity: 0.16;

      @include media(">=xl") {
        content: none;
      }
    }
  }

  &__head {
    margin-bottom: rem(32);
    grid-area: head;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    font-weight: 900;
    @include font(32, 42, 0.96);
    position: relative;

    .tag {
      background-color: var(--primary-blue);
      position: absolute;
      top: -75%;
      left: 50%;
      transform: translateX(-50%);
      font-family: "BarlowCondensed";
      @include font(14, 24, 2);
      padding: rem(2) rem(14);
      border-radius: rem(4);

      @include media(">=xl") {
        left: 68%;
        top: 28%;
      }

      &.bestseller {
        background-color: var(--primary-red);
      }
    }

    .text {
      span {
        margin-right: rem(8);
        display: inline-block;
        color: var(--color-white);
        -webkit-text-fill-color: var(--bg-color);
        -webkit-text-stroke-width: rem(1.5);
        -webkit-text-stroke-color: var(--color-white);
        -webkit-text-fill-color: transparent;
      }
    }

    @include media(">=xl") {
      @include font(42, 56, 0.96);
      text-align: left;
      margin-top: rem(48);
      margin-bottom: rem(36);
    }
  }

  &__image {
    grid-area: img;
    margin: 0 auto;
    border-radius: rem(7.43);
    overflow: hidden;
    margin-bottom: rem(24);

    img {
      width: 100%;
      max-width: rem(618);
      max-height: rem(825);
    }

    @include media(">=xl") {
      margin-left: 0;
    }
  }
}
