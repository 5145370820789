@import "/src/assets/css/style.scss";

.our-partners {

    &__wrapper {
        padding: rem(56) 0 rem(87);
    }

    &__header {
        margin-bottom: rem(36);

        & > .heading[data_set=h2] {
            @include font(28, 32, .84);
            margin-bottom: 0;
        }
    }

    .partner {
        margin: 0 rem(12);

        & > a {
            color: var(--color-white);

            & > img {
                border-radius: rem(8);
                backdrop-filter: blur(rem(60));
                max-height: 120px;
                padding: 16px;
                background-color: #fff;
            }

            & > span {
                @include font(16, 24);
                font-weight: 500;
                margin-top: rem(10);
                opacity: .5;
                transition: opacity .3s ease-in-out;

                @include media('>=md') {
                    @include font(18, 26);
                }
            }

            &:hover {
                & > span {
                    opacity: 1;
                }
            }
        }

        &:nth-child(1) {

            img {
                box-shadow: 0px rem(8) rem(156) 0px rgba(119, 251, 72, 0.35);
            }
        }

        &:nth-child(2) {

            img {
                box-shadow: 0px rem(8) rem(128) 0px rgba(251, 72, 72, 0.50);
            }
        }
    }
}
