@import "/src/assets/css/style.scss";

.blog-post {

    &__wrapper {
        padding-bottom: rem(48);

        @include media('>=md') {
            padding-bottom: rem(120);
        }

        & > .container {
            max-width: rem(984);
        }
    }

    &__header {
        margin-bottom: rem(62);
    }

    &__author {
        @include font(16, 24, 3.2);
        font-family: BarlowCondensed;
        font-weight: 700;
        color: var(--bg-color-4);
        margin-bottom: rem(8)
    }

    &__title {
        @include font(32, 42, .96);
        font-weight: 900;
        margin-bottom: rem(23);

        @include media('>=md') {
            @include font(42, 56, 1.26);
        }
    }

    &__read-time {

        & > .svg-container {
            margin-right: rem(12);
        }
        & > span {
            @include font(15, 28, .6);
            font-weight: 800;
            color: var(--bg-color-4);
        }
    }

    &__image {
        border-radius: rem(14);
        margin-bottom: rem(36);
        overflow: hidden;

        @include media('>=md') {
            margin-bottom: rem(66);
        }

        & > img {
            width: 100%;
        }
    }

    &__content {

        & > h2,
        & > p,
        & > ul {
            margin-bottom: rem(24);
        }

        & > h2 {
            @include font(22, 26, .66);

            & > strong {
                font-weight: 900;
            }
        }
        & > p {
            font-weight: 500;
            opacity: .8;
        }
        & > ul {
            padding: 0 rem(15);
        }
    }

    &__footer {
        margin-top: rem(48);

        @include media('>=md') {
            margin-top: rem(64);
        }

        & > a {
            @include font(17, 20.4, 0.85);
            color: var(--color-white);
            font-weight: 800;
            border-radius: rem(4);
            background-color: transparent;
            padding: rem(13) rem(12) rem(16) rem(19);
            transition: color, background-color .2s ease-in-out;

            &:hover {
                color: var(--bg-color);
                background-color: var(--color-white);

                & > .svg-container > svg > path{
                    stroke: var(--bg-color);
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: rem(-2);
                left: rem(-4);
                width: calc(100% + rem(8));
                min-height: calc(100% + rem(6));
                border-radius: rem(8);
                border-top: rem(2) solid rgba(255, 255, 255, 0.35);
                border-right: rem(4) solid rgba(255, 255, 255, 0.35);
                border-bottom: rem(4) solid rgba(255, 255, 255, 0.35);
                border-left: rem(4) solid rgba(255, 255, 255, 0.35);
            }

            & > .svg-container {
                margin-right: rem(10);
            }

            & > span {
                position: relative;
                top: rem(2);
            }
        }
    }
}
