@import "/src/assets/css/style.scss";

.results {
  background-color: var(--bg-color-6);
  overflow: hidden;

  &.dark {
    background-color: var(--bg-color-1);
  }

  &__wrapper {
    padding: rem(86) 0 rem(85);

    @include media(">=md") {
      padding: rem(92) 0 rem(90);
    }
  }

  &__header {
    text-align: center;
    margin-bottom: rem(49);

    @include media(">=md") {
      margin-bottom: rem(64);
    }

    .header {
      &__content {
        font-weight: 500;

        & > span {
          @include font(16, 24, 3.2);
          font-family: BarlowCondensed;
          font-weight: 700;
          color: var(--primary-blue);
          margin-bottom: rem(5);

          @include media(">=md") {
            margin-bottom: rem(13);
          }
        }

        .heading__wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin-bottom: rem(32);

          @include media(">=md") {
            margin-bottom: rem(25);
          }

          & > h2[data_set="h2"] {
            @include font(32, 42, 0.96);
            font-weight: 900;
            width: 100%;
            order: 0;
            margin-bottom: rem(7);

            @include media(">=md") {
              @include font(42, 56, 1.26);
              width: auto;
              order: 1;
              margin: 0 rem(24);
            }
          }

          & > svg {
            max-width: rem(22.74);

            @include media(">=md") {
              max-width: unset;
            }

            &:first-of-type {
              order: 1;
              margin-right: rem(16);

              @include media(">=md") {
                order: 0;
                margin-right: 0;
              }
            }

            &:last-of-type {
              order: 2;
            }
          }
        }

        & > p {
          max-width: rem(658);
          margin: 0 auto;
        }
      }
    }
  }

  &__slider {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-color: var(--bg-color-6);
      z-index: 2;
    }

    &.dark {
      &::before {
        background-color: var(--bg-color-1);
      }
    }
  }

  .splide {
    &__track {
      overflow: visible;
    }

    &__list {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &__slide {
      max-width: calc(100% - rem(36)) !important;
    }

    .slide {
      &__content {
        overflow: hidden;

        & > img {
          border-radius: rem(14);
        }
      }
    }
  }

  .slider {
    &__navigation {
      width: rem(204);
      min-height: rem(42);
      margin: rem(35) auto 0;

      @include media(">=md") {
        margin: rem(63) auto 0;
      }
    }

    &__arrow {
      top: 0;
      transition: opacity 0.1s ease-in-out;

      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
        transform: rotate(180deg);
      }

      &--disabled {
        opacity: 0.25;
      }
    }

    &__pagination {
      width: 100%;
      text-align: center;

      .item {
        margin-right: rem(8);

        &:last-of-type {
          margin-right: 0;
        }

        & > span {
          min-width: rem(12);
          min-height: rem(12);
          border: rem(2) solid var(--color-white);
          transition: background-color 0.1s ease-in-out;
        }

        &.active {
          & > span {
            background-color: var(--color-white);
          }
        }
      }
    }
  }
}
