@import "/src/assets/css/style.scss";

.breadcrumbs {

    &__wrapper {
        @include font(13, 15.6, .26);
        font-weight: 500;
        padding: rem(15) 0 rem(24);

        @include media('>=md') {
            padding: rem(16) 0 rem(31);
        }

        a {
            font-weight: 500;
            color: var(--color-white);
            text-decoration: none;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: 1;
            }
        }

        a,
        .svg-container {
            opacity: .5;
        }

        .svg-container {
            margin: 0 rem(8);
        }
    }
}
