@import "/src/assets/css/style.scss";

.blog-list {
    margin: rem(64) 0;

    @include media('>=md') {
        margin: rem(64) 0 rem(120);
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: rem(48) rem(24);

        @include media('>=md') {
            grid-template-columns: 1fr 1fr;
        }

        @include media('>=xl') {
            grid-template-columns: 1fr 1fr 1fr;
            gap: rem(86) rem(24);
        }
    }

    &__sorting {
        text-align: right;
        margin-bottom: rem(36);

        & > span {
            @include font(13, 15.6, .26);
            font-weight: 500;
            margin-right: rem(16);
        }
        & > .nice-select {
            display: inline-block;
            float: unset;
        }

        .blog-sort-select {
            @include font(16, 24, 0);
            width: 100%;
            max-width: rem(239);
            height: auto;
            font-weight: 500;
            background-color: var(--bg-color-2);
            border-radius: rem(8);
            border: none;
            padding: rem(12) rem(16);

            @include media('>=md') {
                max-width: rem(230);
            }

            &::after {
                content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiNFQkVFRjIiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K");
                top: rem(12);
                right: rem(16);
                min-width: rem(24);
                min-height: rem(24);
                margin: 0;
                transform: none;
                border: none;
            }

            &.open {
                border-radius: rem(8) rem(8) 0 0;
            }

            .nice-select {

                &-dropdown {
                    width: 100%;
                    margin-top: 0;
                    border-radius: 0 0 rem(8) rem(8);
                    background-color: var(--bg-color-2);

                    .list {
                        border-radius: 0;
                        margin-bottom: 0;

                        .option {
                            background-color: var(--bg-color-2);

                            &:hover,
                            &.focus,
                            &.selected.focus {
                                background-color: var(--bg-color-5);
                            }
                        }
                    }
                }
            }
        }
    }

    &__post {
        @include font(14, 20);
        color: var(--color-white);

        & > a {
            color: currentColor;

            & > a {
                @include font(15, 18, 0.6);
                color: var(--color-white);
                font-weight: 800;
                border-radius: rem(4);
                background-color: transparent;
                padding: rem(6) rem(21) rem(10) rem(22);
                transition: color, background-color .2s ease-in-out;

                &:hover {
                    color: var(--bg-color);
                    background-color: var(--color-white);

                    & > .svg-container > svg > path{
                        stroke: var(--bg-color);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: rem(-2);
                    left: rem(-4);
                    width: calc(100% + rem(8));
                    min-height: calc(100% + rem(6));
                    border-radius: rem(8);
                    border-top: rem(2) solid rgba(255, 255, 255, 0.35);
                    border-right: rem(4) solid rgba(255, 255, 255, 0.35);
                    border-bottom: rem(4) solid rgba(255, 255, 255, 0.35);
                    border-left: rem(4) solid rgba(255, 255, 255, 0.35);
                }

                & > span {
                    position: relative;
                    top: rem(2);
                }
            }
        }

        .post {

            &__image {
                min-height: rem(296);
                border-radius: rem(14);
                margin-bottom: rem(24);
                overflow: hidden;

                & > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover
                }
            }

            &__title {
                @include font(22, 26, .66);
                font-weight: 900;
                margin-bottom: rem(17);
            }

            &__short {
                font-weight: 500;
                margin-bottom: rem(18);
                opacity: .75;
            }

            &__read-time {
                margin-bottom: rem(24);

                & > .svg-container {
                    margin-right: rem(12);
                }

                & > span {
                    @include font(15, 16, .6);
                    font-weight: 800;
                    color: var(--bg-color-4);
                }
            }
        }
    }
}
