@import "/src/assets/css/style.scss";

.numbers {

    &__wrapper {
        padding: rem(55) 0 rem(63);

        @include media('>=md') {
            padding: rem(64) 0 rem(120);
        }
    }

    &__header {
        margin-bottom: rem(28);

        @include media('>=md') {
            margin-bottom: rem(53);
        }

        .heading[data_set=h2] {
            @include font(16, 24, 3.2);
            font-family: 'BarlowCondensed';
            font-weight: 700;
            color: var(--primary-blue);
            margin-bottom: rem(5);

            @include media('>=md') {
                margin-bottom: rem(8);
            }
        }

        .heading[data_set=h3] {
            @include font(32, 42, .96);
            margin-bottom: 0;

            @include media('>=md') {
                @include font(42, 56, 1.26);
            }
        }
    }

    &__cols {
        align-items: flex-start;

        @include media('>=md') {
            max-width: rem(672);
            margin: 0 auto;
        }

        @include media('>=lg') {
            align-items: unset;
            max-width: rem(1116);
        }
    }

    &__col {
        flex: 1 1 50%;
        max-width: calc(50% - rem(12));
        border-radius: rem(14);
        box-shadow: rem(3) rem(23) rem(48) 0px rgba(0, 0, 0, 0.35);
        background-color: var(--bg-color-2);
        margin-bottom: rem(24);

        @include media('>=lg') {
            flex: 1 1 20%;
            max-width: calc(20% - rem(19));
            margin: 0 !important;
        }

        &:last-of-type {
            margin: 0 auto;
        }

        .col {
            &__wrapper {
                @include font(14, 20);
                display: flex;
                flex-direction: column;
                align-items: center;
                min-height: rem(220);
                font-weight: 500;
                padding: rem(30) rem(21) rem(23);

                @include media('>=md') {
                    @include font(18, 26);
                }

                @include media('>=lg') {
                    min-height: rem(273);
                    padding: rem(34) rem(18) rem(48);
                }

                & > p {
                    margin-bottom: rem(21);
                    opacity: .8;
                }

                & > svg {
                    max-width: rem(24);

                    @include media('>=lg') {
                        max-width: rem(36);
                        margin-top: auto;
                    }
                }
            }

            &__header {
                margin-bottom: rem(14);

                & > .number {
                    @include font(40, 48, 1.2);
                    font-weight: 900;

                    @include media('>=md') {
                        @include font(64, 68, 1.92);
                    }
                }
            }
        }
    }
}
