@import "/src/assets/css/style.scss";

.details {

  &__form {

    .form {
      max-width: rem(435);
      margin: 0 auto;

      @include media('>=md') {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }

      @include media('>=lg') {
        padding-top: rem(1);
      }

      .form {
        &__wrapper {
          display: flex;
          flex-direction: column;

          @include media('>=md') {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          @include media('>=lg') {
            padding: 0 0 0 rem(2);
          }
        }

        &-group {
          margin-bottom: rem(23);

          &:last-of-type {
            margin-bottom: rem(34);
          }

          @include media('>=md') {
            flex: 1 1 33%;
            max-width: calc(33% - rem(10));
            margin-bottom: rem(35);
          }

          @include media('>=lg') {
            flex: 1 1 50%;
            max-width: calc(50% - rem(10));
          }

          @include media('>=xl') {
            flex: 1 1 33%;
            max-width: calc(33% - rem(10));
          }

          &-row {
            .form-field {
              padding: rem(6);

              .form-button {
                flex: 1;
                font-weight: 600;
                padding: rem(12) rem(9);
                border-radius: rem(8);
                transition: background-color 0.2s ease-in-out;

                &--active {
                  background-color: var(--primary-red);
                  box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);
                  backdrop-filter: blur(rem(30));
                }
              }
            }
          }
        }

        &-field {
          padding: rem(12) rem(16);
        }

        &-control {
          @include font(16, 24);
        }
      }

      .btn {
        padding: rem(12) rem(29) rem(11);
        margin: 0 auto;

        @include media('>=md') {
          margin: 0;
        }
      }
    }
  }
}
