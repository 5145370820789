@import "/src/assets/css/style.scss";

.error-text {
  color: var(--primary-red);
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: rem(12);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: rem(24);
  width: rem(24);
  background-color: var(--bg-color-1);
  border-radius: rem(8);
}

.checkbox-container:hover input ~ .checkmark {
  background-color: var(--bg-color-1);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: rem(8);
  top: rem(4);
  width: rem(8);
  height: rem(14);
  border: solid var(--primary-blue);
  border-width: 0 rem(3) rem(3) 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
