@import "/src/assets/css/style.scss";

.button_link_secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  padding: rem(12) rem(16);
  border-top: 0.125rem solid;
  border-right: 0.25rem solid;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid;
  border-color: var(--color-white);
  background-color: transparent;
  opacity: 0.8;
  border-radius: rem(8);
  font-size: 1.0625rem;
  line-height: 1.275rem;
  font-weight: 800;
  font-style: italic;
  transition: 0.3s;
  text-transform: uppercase;

  &:hover {
    border-color: #ebebeb;
    color: black;
    text-decoration: none;
    background-color: white;
  }
}

.button_link_primary {
  text-align: center;
  text-decoration: none;
  color: var(--color-white);
  border: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  max-height: rem(44);
  font-size: 1.0625rem;
  line-height: 1.275rem;
  font-weight: 800;
  font-style: italic;
  letter-spacing: 0.053125rem;
  text-transform: uppercase;
  border-radius: 0.5rem;

  position: relative;
  padding: 0.75rem 4.125rem;
  border-color: var(--button-color);
  background-color: var(--button-color);
  box-shadow: 0px 0.25rem 4rem 0px rgba(251, 72, 72, 0.4);
  backdrop-filter: blur(1.875rem);

  &:hover {
    &::after {
      background-color: var(--primary-red-light);
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 0.5rem;
    z-index: -1;
    transition: 0.3s;
  }

  &::before {
    top: 0.1875rem;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.0625rem);
    background-color: var(--secondary-red);
  }

  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--button-color);
  }

  &:hover {
    color: var(--color-white);
    background-color: transparent;
    border: none;
    text-decoration: none;
  }

  &.blue {
    background-color: var(--primary-blue);
    -webkit-box-shadow: 0px 4px 64px 0px rgba(33, 122, 255, 0.4);
    -moz-box-shadow: 0px 4px 64px 0px rgba(33, 122, 255, 0.4);
    box-shadow: 0px 4px 64px 0px rgba(33, 122, 255, 0.4);

    &::before {
      background-color: var(--secondary-blue);
    }

    &::after {
      background-color: var(--primary-blue);
    }
  }

  a {
    text-align: center;
  }
}
