@import "/src/assets/css/style.scss";

.hero {
  position: relative;
  display: flex;
  max-width: rem(1440);
  min-height: rem(738);
  padding: rem(47) 0 rem(55);
  margin: 0 auto;
  overflow: hidden;

  @include media(">=lg") {
    min-height: rem(551);
    padding: rem(72) 0 rem(55);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: rem(243);
    background: linear-gradient(0deg, #222529 0%, rgba(34, 37, 41, 0) 100%);
    z-index: -1;

    @include media(">=lg") {
      content: none;
    }
  }

  &__video {
    top: 0;
    left: 0;
    max-width: rem(1440);
    padding: 0 rem(16);
    border-radius: rem(14);
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    @include media('>=md') {
      padding: 0 rem(48);
    }

    &[aria-hidden="false"] {
      opacity: 1;
      visibility: visible;
      z-index: 2;
    }

    & > video {
      border-radius: rem(14);
      overflow: hidden;
      object-fit: cover;
      cursor: pointer;
    }
  }

  & > .container {
    display: flex;
    justify-content: center;
  }

  &__wrapper {
    flex-direction: column;

    @include media(">=lg") {
      flex-direction: row;
    }
  }

  &__content {
    position: relative;
    text-align: center;

    @include media(">=lg") {
      text-align: left;
    }

    @include media(">=xl") {
      padding-left: calc(8% + rem(7));
    }

    & > .heading[data_set="h2"] {
      @include font(48, 56, 1.44);
      margin-bottom: rem(32);

      @include media(">=md") {
        @include font(72, 76, 2.16);
      }

      @include media(">=lg") {
        text-align: left;
        margin-bottom: rem(38);
      }

      & > span {
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &__header {
    justify-content: center;
    margin-bottom: rem(24);

    @include media(">=lg") {
      justify-content: flex-start;
      padding-left: rem(5);
      margin-bottom: rem(34);
    }

    & > h1 {
      font-family: "BarlowCondensed";
      @include font(16, 24, 3.2);
      color: var(--primary-red);
      margin-right: rem(9);

      @include media(">=md") {
        @include font(20, 24, 4);
      }
    }
  }

  &__buttons {
    display: inline-flex;
    flex-direction: column;

    @include media(">=lg") {
      flex-direction: row;
      align-items: center;
      padding-left: rem(5);
    }

    .button_link_primary {
      @include font(15, 18, 0.6);
      padding: rem(12) rem(48);

      @include media(">=lg") {
        padding: rem(12) rem(50);
      }

      & + span {
        @include font(15, 18, 0.6);
        font-weight: 700;
        margin: rem(16) 0;
        opacity: 0.5;

        @include media(">=lg") {
          margin: 0 rem(18) 0 rem(25);
        }

        & + a {
          color: var(--color-white);
        }
      }
    }

    .hero__video-trigger {
      padding: 0 0 0 rem(5);
      cursor: pointer;

      & > span {
        @include font(15, 18, 0.6);
        font-weight: 800;
        margin-left: rem(11);
        opacity: 0.75;
      }

      svg {
        rect {
          transition: 0.3s;
        }
      }

      &:hover {
        svg {
          rect {
            fill: var(--primary-red);
          }
        }
      }
    }
  }

  &__cards {
    position: relative;
    margin-top: auto;
    z-index: 1;

    @include media(">=sm") {
      width: 100%;
      max-width: rem(596);
      margin: auto auto 0;
    }

    .hero__card {
      flex: 1 1 50%;
      max-width: calc(50% - rem(4));
      border-radius: rem(14);
      backdrop-filter: blur(rem(12));
      background-color: rgba(20, 24, 30, 0.5);

      @include media(">=sm") {
        max-width: calc(50% - rem(12));
      }

      &:nth-child(1) {
        margin: 0 auto;
        box-shadow: 0px rem(4) rem(64) 0px rgba(33, 122, 255, 0.4);

        .svg-container {
          background-color: var(--primary-blue);
        }
      }

      &:nth-child(2) {
        backdrop-filter: blur(30px);
        box-shadow: 0px rem(4) rem(64) 0px rgba(251, 72, 72, 0.4);

        .svg-container {
          background-color: var(--primary-red);
        }
      }

      & > a {
        color: var(--color-white);
        text-decoration: none;

        &:hover {
          .card__content {
            opacity: 1;
          }
        }
      }

      .card {
        &__wrapper {
          padding: rem(12) rem(19);

          @include media(">=xl") {
            padding: rem(19) rem(19) rem(17);
          }
        }

        &__header {
          & > h4 {
            @include font(15, 18, 0.6);
            font-weight: 800;

            @include media(">=lg") {
              @include font(18, 21.6, 0.54);
            }

            @include media(">=xl") {
              br {
                display: none;
              }
            }
          }

          & > .svg-container {
            width: 100%;
            max-width: rem(24);
            height: rem(24);
            border-radius: 50%;

            @include media(">=xl") {
              max-width: rem(40);
              border-radius: rem(50);
            }
          }
        }

        &__content {
          @include media(">=xl") {
            @include font(13, 18, 0.13);
            font-weight: 500;
            margin-top: rem(12);
            opacity: 0.75;
            transition: opacity .3s ease-in-out;
          }
        }
      }
    }
  }

  &__image {
    // bottom: rem(82);
    bottom: 0;
    // transform: scale(1.62);
    z-index: -1;

    @include media(">=sm") {
      bottom: 0;
      transform: scale(1.22);
    }

    @include media(">=md") {
      right: 0;
      max-width: rem(853);
      transform: scale(1);
    }

    @include media(">=lg") {
      // right: -12%;
      bottom: rem(67);

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: rem(242);
        background: linear-gradient(0deg, #222529 0%, rgba(34, 37, 41, 0) 100%);
      }
    }

    @include media(">=xl") {
      right: 0;
    }

    @include media(">=xxl") {
      right: calc(15% + rem(6));
    }

    & > .border-line {
      // top: rem(-13);
      z-index: -1;

      // @include media(">=lg") {
      //   top: rem(-33);
      // }

      & > svg {
        & > path {
          animation: borderLine 1.5s infinite;

          &:nth-child(1) {
            animation-delay: 0.2s, 0.2s;
          }

          &:nth-child(2) {
            animation-delay: 0.3s, 0.3s;
          }

          &:nth-child(3) {
            margin-right: 0 !important;
            animation-delay: 0.5s, 0.5s;
          }

          &:nth-child(4) {
            animation-delay: 0.2s, 0.2s;
          }

          &:nth-child(5) {
            animation-delay: 0.3s, 0.3s;
          }

          &:nth-child(6) {
            margin-right: 0 !important;
            animation-delay: 0.5s, 0.5s;
          }
        }
      }
    }

    & > .animated-arrows {
      display: none !important;

      @include media(">=xxl") {
        display: inline-flex !important;

        &:nth-child(1) {
          bottom: calc(34% + rem(67));
          left: calc(5% + rem(2));
        }

        &:nth-child(2) {
          bottom: calc(34% + rem(52));
          left: calc(15% - rem(5));
        }

        &:nth-child(3) {
          bottom: calc(34% - rem(2));
          left: rem(55);
        }

        &:nth-child(4) {
          top: rem(37);
          right: rem(50);
        }

        &:nth-child(5) {
          top: rem(33);
          right: calc(15% + rem(1));
        }

        &:nth-child(6) {
          top: calc(26% + rem(1));
          right: rem(66);
        }
      }
    }
  }

  .animated-arrows {
    position: absolute;
    z-index: -1;

    &--mobile {
      @include media(">=lg") {
        display: none !important;
      }

      &:nth-child(1) {
        top: rem(40);
        right: rem(16);
      }

      &:nth-child(2) {
        bottom: rem(15);
        left: rem(29);
      }

      &:nth-child(3) {
        bottom: rem(64);
        left: rem(9);
      }
    }
  }
}

@keyframes borderLine {
  0%,
  100% {
    opacity: 0.15;
  }
  50% {
    opacity: 1;
  }
}

@keyframes hide {
  0%,
  33.333% {
    opacity: 1;
  }
  33.333%,
  100% {
    opacity: 0;
  }
}
