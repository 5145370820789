@import "/src/assets/css/style.scss";

.about-us {

    &__wrapper {
        padding: rem(69) 0 rem(64);

        @include media('>=lg') {
            padding: rem(120) 0;
        }
    }

    &__cols {
        max-width: rem(656);
        margin: 0 auto rem(124);
        scroll-margin-top: rem(124);

        @include media('>=md') {
            margin-bottom: rem(120);
            scroll-margin-top: rem(120);
        }

        &:first-of-type {
            scroll-margin-top: rem(69);

            @include media('>=lg') {
                scroll-margin-top: rem(120);
            }
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media('>=lg') {
            display: flex;
            justify-content: space-between;
            max-width: 100%;

            &--reverse {
                flex-direction: row-reverse;
            }
        }

        @include media('>=xxl') {
            align-items: center;
        }
    }

    &__col {

        @include media('>=lg') {
            flex: 1;
            width: 100%;
        }

        &--text {
            margin-bottom: rem(47);

            @include media('>=lg') {
                max-width: calc(50% - rem(11));
                margin-bottom: 0;
            }

            @include media('>=xl') {
                padding: 0 rem(64);
            }
        }

        &--media {
            border-radius: rem(14);
            overflow: hidden;

            @include media('>=lg') {
                max-width: calc(50% - rem(16));
            }

            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & > .col__header {
            @include font(16, 24, .16);
            text-align: center;
            margin-bottom: rem(16);

            @include media('>=lg') {
                text-align: left;
            }

            & > .heading[data_set=h2] {
                @include font(40, 48, 1.2);
                margin-bottom: rem(12);

                @include media('>=md') {
                    @include font(56, 68, 1.68);
                }

                @include media('>=lg') {
                    display: flex;
                    align-items: center;
                }

                @include media('>=xl') {
                    margin-bottom: rem(20);
                }

                & > span {
                    @include font(32, 42, .96);
                    position: relative;
                    top: rem(-11);
                    width: 100%;
                    -webkit-text-fill-color: transparent;

                    @include media('>=md') {
                        @include font(34, 22, 1.02);
                    }

                    @include media('>=lg') {
                        width: auto;
                        top: 0;
                    }
                }
            }

            & > p {
                margin-bottom: rem(24);
                opacity: .8;

                &:last-of-type {
                    margin-bottom: rem(25);

                    @include media('>=xl') {
                        margin-bottom: rem(35);
                    }
                }
            }

            & > div {
                font-weight: 500;

                & > span {
                    margin-left: rem(11);
                }
            }
        }

        & > ul {

            & > li {
                font-weight: 500;
                margin-bottom: rem(12);

                &:last-of-type {
                    margin-bottom: 0;
                }

                & > svg {
                    min-width: rem(20);

                    & + span {
                        margin-left: rem(12);
                    }
                }
            }
        }
    }
}
