@import "/src/assets/css/style.scss";

.cta {
    padding: rem(64) 0;

    .container {
        @include media("<sm") {
            padding: 0;
        }
    }

    &__wrapper {
        padding: rem(84) 0 rem(92);
        border-radius: rem(14);
        background: linear-gradient(96deg, #F30A2E -3.21%, #0743B9 107.31%);
        background-size: 200% 200%;
        overflow: hidden;
        animation: gradient 4s ease infinite;

        @include media(">=md") {
            padding: rem(53) 0 rem(61);
        }

        .svg-container {
            position: absolute;
            bottom: rem(4);
            z-index: 1;

            @include media(">=md") {
                bottom: 0;
            }

            &--left {
                left: rem(-116);
                color: var(--third-blue);

                @include media(">=md") {
                    left: 0;
                }
            }

            &--right {
                right: rem(-116);
                color: var(--third-red);
                transform: scaleX(-1);

                @include media(">=md") {
                    right: 0;
                }
            }
        }
    }

    &__heading {
        display: flex;

        .heading {
            @include font(42, 42, 1.26);
            white-space: nowrap;
            margin-right: rem(20);

            @include media(">=md") {
                @include font(56, 56, 1.68);
                margin-right: rem(19);
            }

            &:not(:nth-child(3)) {
                opacity: .5;
            }

            &:last-of-type {
                margin-right: 0;
            }

            & > span {
                -webkit-text-fill-color: transparent;
            }
        }
    }

    &__header {
        z-index: 2;

        & + .heading {
            position: relative;
            @include font(42, 48, 1.26);
            margin: rem(-8) 0 rem(29);
            z-index: 2;

            @include media(">=md") {
                @include font(56, 56, 1.68);
                margin: rem(-2) 0 rem(30);
            }
        }
    }

    &__content {
        @include font(18, 26);
        font-weight: 500;
        padding: 0 rem(16);
        z-index: 2;

        & > p {
            margin-bottom: rem(35);

            @include media(">=md") {
                margin-bottom: rem(24);
            }
        }
    }

    .button_link_primary  {
        display: inline-block;
        padding: rem(12) rem(44);
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
