@import "/src/assets/css/style.scss";

.video-exercise-page {
    padding-bottom: rem(48);

    @include media('>=lg') {
        padding: 0 0 rem(116) rem(3);
    }

    .category {

        &-section {
            margin-bottom: rem(16);

            @include media('>=lg') {
                margin-bottom: rem(37);
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__header {
            padding-bottom: rem(15);
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                min-height: rem(1);
                background-color: var(--color-white);
                opacity: .25;
            }

            & > h3 {
                @include font(22, 26, .66);
                font-weight: 900;
                color: var(--color-white);
            }
        }
    }

    .video {

        &__list {
            justify-content: space-between;
            height: 0;
            transition: height .3s ease-out;

            @include media('>=sm') {
                justify-content: flex-start;
            }

            &.expanded {
                height: auto;
            }
        }

        &__item {
            flex: 1 1 50%;
            max-width: calc(50% - rem(8));
            margin: rem(24) 0;

            @include media('>=sm') {
                flex: auto;
                width: 100%;
                max-width: rem(188.2);
                margin: rem(26) rem(15) rem(24) 0;
                margin-right: rem(15);

                &:nth-of-type(5n) {
                    margin-right: 0;
                }
            }

            &:last-of-type {
                margin-bottom: rem(61);

                @include media('>=sm') {
                    margin-bottom: rem(29);
                }
            }

            & > a {
                color: var(--color-white);

                &:hover {

                    .svg-container {
                        opacity: 1;
                    }
                }

                & > h4 {
                    @include font(14, 20);
                    font-weight: 500;
                }
            }
        }

        &__thumbnail {
            border-radius: rem(8);
            margin-bottom: rem(8);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--bg-color-7);
                mix-blend-mode: multiply;
            }

            & > img {
                min-height: rem(116);
                object-fit: cover;
            }

            & > .svg-container {
                top: 50%;
                left: 50%;
                min-width: rem(40);
                min-height: rem(40);
                transform: translate(-50%, -50%);
                opacity: .5;
                z-index: 2;
                transition: opacity .3s ease-out;
            }
        }
    }
}
