@import "/src/assets/css/style.scss";

.container_not_found {
  margin-top: rem(96);
  padding-bottom: rem(184);

  h1 {
    @include font(16, 24);
  }

  .heading {
    @include font(56, 68);
  }

  p {
    max-width: rem(880);
    text-align: center;
    @include font(16, 24);
    margin-top: rem(48);
    margin-bottom: rem(80);
  }
}
