@import "/src/assets/css/style.scss";

.dashboard {

    &__details {
        padding-bottom: rem(45);

        @include media('>=md') {
            padding-bottom: rem(125);
        }
    }

    .details {

        &__header {
            margin-bottom: rem(47);

            @include media('>=lg') {
                margin-bottom: rem(45);
            }
        }
    }
}
