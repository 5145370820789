@import "/src/assets/css/style.scss";

.header {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  .header-outer-wrapper {
    @include font(15, 16, 0.45);
    font-weight: 600;
    width: 100%;
    max-width: rem(1440);
    padding: 0 rem(16);
    background-color: var(--bg-color);
    color: white;
    height: 86px;
    display: grid;
    align-items: center;
    grid-template-columns: 100px 1fr 100px;
    grid-template-rows: 1fr;
    justify-content: center;

    .right-nav,
    .left-nav {
      display: none;
    }

    .icons-wr {
      display: flex;
      gap: 20px;

      svg {
        fill: gray;
        height: 22px;
        width: auto;
      }
    }

    a {
      max-width: rem(139);
      color: white;
      transition: 0.2s ease-in-out;

      @include media('>=md') {
        max-width: rem(178);
      }

      &:hover {
        color: var(--primary-red);
        text-decoration: none;
      }
    }
  }

  .header-opinion-wr {
    display: flex;
    flex-direction: column;
    gap: rem(7);

    span {
      @include font(13, 15.6, 0.39);
      display: flex;
      align-items: center;
      gap: 6px;

      &:nth-child(2) {
        justify-content: flex-end;
        flex-direction: row-reverse;
        font-weight: 800;
      }
    }

    svg {
      fill: var(--primary-red);
      max-width: rem(20);
    }
  }

  .logo-wr {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: rem(11);

    & > a {
      max-width: rem(139);

      @include media('>=md') {
        max-width: rem(178);
      }
    }

    svg {
      width: rem(140);
      height: auto;
    }
  }

  .burger-menu {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 16px;
    z-index: 1000;
    background: #14181e;
    border-radius: 50%;
    width: 44px;
    height: 44px;

    .burger-icon {
      z-index: 100;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 6px;

      .bar {
        background-color: #fff;
        width: 24px;
        border-radius: 2px;
        height: 2px;
        transition: 0.4s;
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      background-color: var(--bg-color);
      display: none;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &__logo {
        display: flex;
        justify-content: center;
        padding-top: rem(24);

        svg {
          width: rem(140);
          height: auto;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        width: 100%;
        padding-top: rem(20);
        height: 100%;

        li {
          padding: 10px 0;
          margin-top: rem(16);
          cursor: pointer;
          transition: background-color 0.3s;
          text-align: center;

          a {
            width: 100%;
            display: inline-block;
          }

          &:hover {
            a {
              color: var(--primary-red);
            }
          }
        }

        .panel-link {
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 12px;
          border-radius: 8px;
          border: 3px solid var(--secondary-red);
          width: rem(200);
          margin: 0 auto;
          margin-top: rem(20);

          svg {
            margin-left: rem(8);
          }

          &:hover {
            background-color: var(--primary-red);
            svg {
              fill: var(--primary-red);
            }
          }
        }
      }
    }

    &.open {
      position: fixed;
      top: rem(20);
      .burger-icon {
        .bar:first-child {
          transform: rotate(-45deg) translate(-5px, 6px);
        }
        .bar:nth-child(2) {
          opacity: 0;
        }
        .bar:last-child {
          transform: rotate(45deg) translate(-5px, -6px);
        }
      }

      .menu {
        display: flex;
      }
    }
  }
}

@media (min-width: 1024px) {
  .header {
    .header-outer-wrapper {
      padding: 0 rem(48);
      height: 120px;
      grid-template-columns: 3fr 2fr 3fr;
      grid-template-rows: 1fr;

      .header-left-side,
      .right-nav {
        height: 100%;
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

      .left-nav,
      .right-nav-links {
        height: 50%;
        display: flex;
        align-items: center;

        display: flex;
        justify-content: space-between;

        .panel-link {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 12px;
          border-radius: 8px;
          border: 3px solid var(--third-red);
          transition: 0.3s;

          svg {
            margin-left: rem(8);
          }

          &:hover {
            color: var(--color-white);
            background-color: var(--primary-red);
            svg {
              fill: var(--primary-red);
            }
          }
        }
      }

      .right-nav-social {
        display: flex;

        svg {
          margin-right: rem(24);
          fill: var(--gray-50);
          width: 100%;
          max-width: rem(18);
          height: rem(18);
          cursor: pointer;
          transition: 0.2s ease-in-out;

          &:hover {
            fill: var(--primary-red);
          }
        }
      }
    }

    .header-opinion-wr {
      justify-content: flex-end;
      flex-direction: row;
      gap: 10px;
    }

    .burger-menu {
      display: none;
    }
  }
}
