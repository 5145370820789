@import "/src/assets/css/style.scss";

.poll {
  &__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(36);
    row-gap: rem(12);

    &-answear {
      background-color: var(--bg-color-2);
      width: fit-content;
      padding: rem(8) rem(16);
      margin-right: rem(8);
      border-radius: rem(24);
      box-shadow: 3px 23px 48px 0px rgba(0, 0, 0, 0.35);
      border: rem(2) solid var(--bg-color-2);
      display: flex;

      .dot {
        background-color: var(--bg-color-3);
        display: block;
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        margin-right: rem(12);
        border: rem(6) var(--bg-color-3) solid;
      }

      &.active {
        border-color: #d4243f;
        box-shadow: 0px 4px 64px 0px rgba(251, 72, 72, 0.4);

        .dot {
          background-color: #d4243f;
        }
      }
    }
  }
}

.modal__title {
  text-overflow: ellipsis;
  max-width: rem(400);
  text-transform: uppercase;
  @include font(28, 32, 0.84);
  font-weight: 900;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
}

.modal__client {
  margin-top: rem(16);
  @include font(14, 20);
  font-weight: 500;
  opacity: 0.8;
  position: relative;
  padding-bottom: rem(36);

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: rem(2);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.16;
  }
}

.label {
  font-weight: 800;
  @include font(14, 20, 0.56);
  font-style: italic;
  text-transform: uppercase;
  margin-bottom: rem(12);
  display: block;
}

.data__wr {
  padding: rem(12);
  border-radius: rem(8);
  border: rem(2) solid black;
  margin-right: rem(4);
  @include font(16, 16, 0.16);
  font-weight: 600;
}

.bold_text {
  font-weight: 800;
}

.modal__body {
  padding: rem(24);
  &-content {
    background-color: var(--bg-color-3);
    padding: rem(12) rem(16);
    border-radius: rem(8);
    color: var(--gray-50);
    font-weight: 500;
  }

  &-head {
    flex-direction: column;
    margin-bottom: rem(24);

    @include media(">=lg") {
      flex-direction: row;
    }

    .time {
      margin-top: rem(16);

      @include media(">=lg") {
        margin-left: rem(64);

        margin-top: 0;
      }

      &__dates {
        flex-direction: column;

        @include media(">=md") {
          flex-direction: row;
        }
      }
    }
  }

  @include media(">=xl") {
    padding: rem(48);
  }
}

.modal__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: rem(36);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: rem(2);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.16;
  }
}
