@import "/src/assets/css/style.scss";

.dashboard {

    &__reports {
        max-width: rem(672);
        padding-bottom: rem(48);
        margin: 0 auto;

        @include media('>=md') {
            padding-bottom: rem(120);
        }

        @include media('>=lg') {
            max-width: 100%;
        }
    }

    .reports {

        &__header {

            @include media('>=lg') {
                padding-bottom: rem(49);
                margin-bottom: rem(35);
            }

            &::after {
                min-height: rem(2);
            }
        }

        &__list {

            & > .alert-warning {
                border: none;
                color: var(--primary-red);
                background-color: var(--bg-color-2);

                & > .close {

                    & > span {
                        text-shadow: none;
                        opacity: 1 !important;
                        transition: color 100ms ease-out;
                    }
                }
            }
        }

        .heading_route {

            @include media('>=lg') {
              margin-bottom: rem(24);
            }
        }

        &__content {

            @include media('>=lg') {
                padding-left: rem(2);
            }

            &__header {
                margin-bottom: rem(35);

                @include media('>=lg') {
                    margin-bottom: rem(31);
                }

                @include media('>=xl') {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-right: rem(3);
                }
            }

            &__col {

                & > span {
                    @include font(13, 15.6, 0.26);
                    font-weight: 500;
                    margin-right: rem(16);
                }

                &--left {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: rem(36);

                    @include media('>=md') {
                        justify-content: flex-start;
                        align-items: center;
                    }

                    @include media('>=xl') {
                        width: 100%;
                        // max-width: rem(435);
                        margin-bottom: 0;
                    }

                    & > .btn {
                        width: 100%;
                        margin-bottom: rem(17);

                        @include media('>=md') {
                            max-width: rem(265);
                            padding: rem(12) 0;
                            margin-right: rem(24);
                            margin-bottom: 0;
                        }

                        & > span {
                            margin-right: rem(9);
                        }

                        & > svg {
                            min-width: rem(20);
                            min-height: rem(20);
                        }
                    }

                    & > span {
                        opacity: .5;
                    }
                }

                &--right {
                    @include media('>=xl') {
                        width: 100%;
                        max-width: rem(318);
                    }
                }
            }
        }

        &__select {
            @include font(16, 24);
            width: 100%;
            max-width: rem(230);
            height: auto;
            font-weight: 500;
            background-color: var(--bg-color-2);
            border-radius: rem(8);
            border: none;
            padding: rem(12) rem(16);

            &::after {
                content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiNFQkVFRjIiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K");
                top: rem(12);
                right: rem(16);
                min-width: rem(24);
                min-height: rem(24);
                margin: 0;
                transform: none;
                border: none;
            }

            &.open {
                border-radius: rem(8) rem(8) 0 0;
            }

            .nice-select {

                &-dropdown {
                    width: 100%;
                    margin-top: 0;
                    border-radius: 0 0 rem(8) rem(8);
                    background-color: var(--bg-color-2);

                    .list {
                        border-radius: 0;
                        margin-bottom: 0;

                        .option {
                            background-color: var(--bg-color-2);

                            &:hover,
                            &.focus,
                            &.selected.focus {
                                background-color: var(--bg-color-5);
                            }
                        }
                    }
                }
            }
        }
    }
}
