@import "/src/assets/css/style.scss";

#main {

  .home__main_paragraph {
    display: flex;
    align-items: center;
    justify-content: center;
    //     @include font(16, 24, 0.2);
    @include font(22, 26, 2);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-red);
    font-style: italic;

    span {
      font-family: 'BarlowCondensed';
      letter-spacing: rem(3.2);
      margin-right: rem(12);
    }
  }

  @include media(">=lg") {
    padding-top: 0;
  }
}
