@import "/src/assets/css/style.scss";

.metamorphoses {

    &__wrapper {
        padding: rem(63) 0;

        @include media('>=md') {
            padding: rem(63) 0 rem(116);
        }
    }

    &__content {

        @include media('>=sm') {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: rem(64) rem(25);
        }

        @include media('>=lg') {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    &__item {
        border-radius: rem(14);
        margin-bottom: rem(23);
        overflow: hidden;

        @include media('>=sm') {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__sorting {
        @include font(13, 15.6, 0.26);
        font-weight: 500;
        max-width: rem(328);
        padding-left: rem(1);
        margin: 0 0 rem(25) auto;

        @include media('>=md') {
            max-width: rem(319);
            margin-bottom: rem(37);
        }
    }

    &__select {
        @include font(16, 24, 0);
        width: 100%;
        max-width: rem(239);
        height: auto;
        font-weight: 500;
        background-color: var(--bg-color-2);
        border-radius: rem(8);
        border: none;
        padding: rem(12) rem(16);

        @include media('>=md') {
            max-width: rem(230);
        }

        &::after {
            content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiNFQkVFRjIiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPgo8L3N2Zz4K");
            top: rem(12);
            right: rem(16);
            min-width: rem(24);
            min-height: rem(24);
            margin: 0;
            transform: none;
            border: none;
        }

        &.open {
            border-radius: rem(8) rem(8) 0 0;
        }

        .nice-select {

            &-dropdown {
                width: 100%;
                margin-top: 0;
                border-radius: 0 0 rem(8) rem(8);
                background-color: var(--bg-color-2);

                .list {
                    border-radius: 0;
                    margin-bottom: 0;

                    .option {
                        background-color: var(--bg-color-2);

                        &:hover,
                        &.focus,
                        &.selected.focus {
                            background-color: var(--bg-color-5);
                        }
                    }
                }
            }
        }
    }
}
