@import "/src/assets/css/style.scss";

.faq {
    background-color: #222529;

    &__wrapper {
        padding: rem(64) 0 rem(63);

        @include media('>=md') {
            padding: rem(94) 0 rem(90);
        }
    }

    &__header {
        margin-bottom: rem(23);

        @include media('>=md') {
            margin-bottom: rem(53);
        }

        & > span {
            @include font(16, 24, 3.2);
            font-family: 'BarlowCondensed';
            font-weight: 700;
            color: var(--primary-blue);
            margin-bottom: rem(5);

            @include media('>=md') {
                margin-bottom: rem(8)
            }
        }

        & > .heading[data_set=h2] {
            @include font(32, 42, .96);
            font-weight: 900;
            margin-bottom: 0;

            @include media('>=md') {
                @include font(42, 56, 1.26);
            }
        }
    }

    &__content {

        .view-more {
            min-width: rem(20);

            @include media('>=lg') {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: rem(86);
                padding-left: rem(21);

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    min-width: rem(2);
                    height: 100%;
                    background-color: var(--color-white);
                    opacity: .25;
                }

                & > span {
                    @include font(12, 16, .12);
                    font-weight: 500;
                }
            }
        }
    }

    &__item {
        max-width: rem(658);
        border-radius: rem(14);
        background-color: var(--bg-color-2);
        margin: 0 auto rem(15);
        cursor: pointer;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__wrapper {
            padding: rem(16) rem(15) rem(17) rem(24);
        }
    }

    &__answer {
        padding: 0 rem(15) rem(17) rem(24);
    }

    &__question {
        justify-content: space-between;
        align-items: center;
        font-weight: 500;

        & > p {
            padding-right: rem(18);
        }
    }
}
