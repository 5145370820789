@import "/src/assets/css/style.scss";
.user {
  &__header {
    margin-bottom: rem(47);

    @include media(">=md") {
      margin-bottom: rem(55);
    }

    @include media(">=xl") {
      display: flex;
    }
  }

  &__info {
    margin-bottom: rem(47);

    @include media(">=xl") {
      width: 100%;
      max-width: rem(316);
      margin-right: rem(27);
      margin-bottom: 0;
    }
  }

  &__avatar {
    width: 100%;
    max-width: rem(100);
    min-height: rem(100);
    border-radius: 50%;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--secondary-blue);
      z-index: -1;
    }

    & > img {
      width: 100%;
      max-width: calc(100% - rem(8));
      border-radius: 50%;
    }
  }

  &__data {
    padding-left: rem(16);

    @include media(">=md") {
      padding-left: rem(18);
    }

    & > span {
      @include font(14, 20);
      opacity: 0.75;

      &.is-strong {
        @include font(16, 24, 0.16);
        opacity: 1;

        strong {
          font-weight: 800;
        }
      }
    }

    &_info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: rem(8);
    }
  }

  &__name {
    margin-bottom: rem(3);
  }

  &__raports {
    margin-top: rem(100);

    .raports__amount {
      display: flex;
      align-items: center;
      @include font(22, 26, 0.66);
      font-weight: 900;
      font-style: italic;
      text-transform: uppercase;

      svg {
        margin-right: rem(8);
      }
    }
  }
}

.training_amount {
  font-weight: 800;
}
