@import "/src/assets/css/style.scss";

.footer-signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: rem(64);
  max-width: rem(1440);
  margin: 0 auto;

  .footer-signup {
    padding: 0 rem(16) 0 rem(16);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;

    .footer-element-wr {
      display: grid;
      grid-template-columns: rem(72) 1fr;
      grid-template-rows: 1fr;
      max-width: rem(328);
      width: 100%;
      background-color: var(--bg-color-1);
      border-radius: rem(14);
      padding: rem(16) rem(16) rem(15);
      margin-bottom: rem(16);
      align-items: center;

      &.primary {
        background-color: var(--bg-color-2);
        // box-shadow: 0 4px 4px 0, rgba(255, 255, 255, 0.25);
        -webkit-box-shadow: 0px 4px 14px px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.75);
      }

      .footer-wrapper-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: rem(16);
        width: rem(56);
        height: 100%;

        svg {
          height: rem(56);
          width: auto;
        }

        & + span {
          @include font(14, 20, 0.28);
          font-weight: 500;
          padding-top: rem(1);

          & > strong {
            font-weight: 800;
          }
        }
      }

      &:nth-child(odd) .footer-wrapper-icon {
        svg {
          fill: var(--primary-red);
        }
      }

      &:nth-child(even) .footer-wrapper-icon {
        svg {
          fill: var(--primary-blue);
        }
      }
    }

    @include media(">=lg") {
      padding: 0;
      margin-bottom: rem(48);
      flex-direction: row;
      height: 100%;

      .footer-element-wr {
        margin: rem(16) 0 0 0;
        height: 100%;
        flex-grow: 1;

        &:not(:last-child) {
          margin-right: rem(16);
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;
    color: var(--gray-50);
    padding: 0 rem(16) rem(64) rem(16);

    .footer-signup-copy {
      text-align: center;
    }

    .footer-signup-nav {
      display: flex;
      justify-content: space-around;
      a {
        color: var(--gray-50);
      }
    }
  }

  @include media(">=lg") {
    margin-bottom: rem(28);

    .footer-bottom {
      flex-direction: row;
      padding: 0 rem(48);
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-top: 0;

      .footer-signup-nav {
        margin-top: 0;

        a {
          margin-left: rem(24);
        }
      }
    }
  }
}
